import {
  Input,
  Radio,
  Select,
  DatePicker,
  Tabs,
  Spin,
  Empty,
} from "@arco-design/web-react";
import { IconSearch } from "@arco-design/web-react/icon";
import React, { useCallback, useRef, useState } from "react";
import "./index.less";
import { inject, observer } from "mobx-react";
import dayjs from "dayjs";
import Nodata from "../componts/Nodata";
import no from "@/assets/teamoverview/no.png";
import { debounce } from "@/utils";
import { getUserListApi } from "@/services/temeover";
const TabPane = Tabs.TabPane;
const ContelSelect = (props) => {
  const [salesNameList, setSalesNameList] = useState([]);
  const [fetching, setFetching] = useState(false);
  const refFetchId = useRef(0);
  // const [nameList, setNameList] = useState([]);
  // const [nameFeching, setNameFeching] = useState(false);
  // const refNameFetchId = useRef(0);
  const { teamover } = props;
  const { RangePicker } = DatePicker;
  const Option = Select.Option;
  const options = [
    {
      value: 1,
      label: "购买权益",
    },
    {
      value: 2,
      label: "平台赠送",
    },
  ];
  const appOptions = [
    // {label:'订单',value: 1},
    // {label:'试用', value: 2},
    // {label: '订单退款', value: 3},
    { label: "视频解析", value: 101 },
    { label: "智能混剪", value: 102 },
    // {label: '团队成员席位', value:103}
  ];
  const [activeTab, setActiveTab] = useState("activeqy");
  const appChange = (value) => {
    const equitySearch = { ...teamover.equitySearch, type: value || 0 };
    teamover.changeEquitySearch(equitySearch);
  };
  const transactionTypeChange = (value) => {
    const transactionSearch = {
      ...teamover.transactionSearch,
      type: value || 0,
    };
    teamover.changeTransactionSearch(transactionSearch);
  };
  const onTransactionUserChange = (value) => {
    const transactionSearch = {
      ...teamover.transactionSearch,
      sales_agent_id: value || 0,
    };
    teamover.changeTransactionSearch(transactionSearch);
  };
  const equityDateChange = (value) => {
    let start = 0;
    let end = 0;
    const equitySearch = { ...teamover.equitySearch };
    if (value?.length > 1) {
      const [start_at, end_at] = value;
      start = dayjs(start_at + " 00:00:00").valueOf() / 1000;
      end = dayjs(end_at + " 23:59:59").valueOf() / 1000;
    }
    equitySearch.start = start;
    equitySearch.end = end;
    teamover.changeEquitySearch(equitySearch);
  };
  const transactionDateChange = (value) => {
    let start = 0;
    let end = 0;
    const transactionSearch = { ...teamover.transactionSearch };
    if (value?.length > 1) {
      const [start_at, end_at] = value;
      start = dayjs(start_at + " 00:00:00").valueOf() / 1000;
      end = dayjs(end_at + " 23:59:59").valueOf() / 1000;
    }
    transactionSearch.start = start;
    transactionSearch.end = end;
    teamover.changeTransactionSearch(transactionSearch);
  };
  const debouncedFetchOptions = useCallback(
    debounce((value: string) => {
      setSalesNameList([]);
      if (!value) return;
      refFetchId.current = Date.now();
      const fetchId = refFetchId.current;
      setFetching(true);
      getUserListApi({ name: value }).then((res) => {
        const result = res.data?.item || [];
        const list = result.filter((i) =>
          i.department_path?.includes("营销部")
        );
        if (refFetchId.current === fetchId) {
          setSalesNameList(
            list.map((item) => {
              return {
                value: item.id,
                label: `${item.nick_name}(${item.employee_no || ""})`,
              };
            })
          );
          setFetching(false);
        }
      });
    }, 500),
    []
  );
  // const debouncedFetchNameOptions = useCallback(
  //   debounce((value: string) => {
  //     setNameList([]);
  //     if (!value) return;
  //     refNameFetchId.current = Date.now();
  //     const fetchId = refNameFetchId.current;
  //     setNameFeching(true);
  //     getNameListApi({ name: value }).then((res) => {
  //       const result = res.data?.item || [];
  //       if (refNameFetchId.current === fetchId) {
  //         setNameList(
  //           result.map((item) => {
  //             return {
  //               value: item.uuid,
  //               label: `${item.nickname}`,
  //             };
  //           })
  //         );
  //         setNameFeching(false);
  //       }
  //     });
  //   }, 500),
  //   []
  // )
  return (
    <div className="contel_componts">
      {/* <Radio.Group
        type="button"
        name="position"
        className={"team_overview_content_tabs"}
        options={["权益流水", "交易记录"]}></Radio.Group> */}
      <div className="Filtememberscontexttabs">
        <Tabs
          activeTab={activeTab}
          onChange={(e) => {
            teamover.setactive(e);
            setActiveTab(e);
          }}
        >
          <TabPane key={"activeqy"} title="权益流水"></TabPane>
          <TabPane key={"activejy"} title="交易记录"></TabPane>
        </Tabs>
      </div>
        <div className="contel_R" style={{display: teamover.dataactive == "activeqy" ? 'flex' : 'none'}}>
          <Input
            prefix={<IconSearch />}
            allowClear
            placeholder="输入账号"
            className="plearthPhone"
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                const equitySearch = {
                  ...teamover.equitySearch,
                  field: e.target.value,
                };
                teamover.changeEquitySearch(equitySearch);
              }
            }}
            onBlur={(e) => {
              const equitySearch = {
                ...teamover.equitySearch,
                field: e.target.value,
              };
              teamover.changeEquitySearch(equitySearch);
            }}
          />
          {/* <Select
            className="optab_select"
            style={{ width: "100%" }}
            showSearch
            options={nameList}
            placeholder="请输入账号搜索"
            filterOption={false}
            onChange={(value) => {
              const equitySearch = {
                ...teamover.equitySearch,
                field: value,
              };
              teamover.changeEquitySearch(equitySearch);
            }}
            allowClear
            notFoundContent={
              nameFeching ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin style={{ margin: 12 }} />
                </div>
              ) : (
                <Empty />
              )
            }
            onSearch={debouncedFetchNameOptions}
          /> */}
          <div>
            <Select
              placeholder="请选择应用类型"
              className={"optab_select"}
              options={appOptions}
              key="1"
              notFoundContent={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={no}
                    alt=""
                    style={{
                      width: "120px",
                      height: "77px",
                      marginTop: "10px",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    暂无内容
                  </p>
                </div>
              }
              onChange={appChange}
              allowClear
            ></Select>
          </div>
          <div>
            <RangePicker
              mode="date"
              className={"select_data"}
              onChange={equityDateChange}
            />
          </div>
        </div>
        <div className="contel_R"  style={{display: teamover.dataactive == "activeqy" ? 'none' : 'flex'}}>
          {/* <Select
            className="optab_select"
            style={{ width: "100%" }}
            showSearch
            options={salesNameList}
            placeholder="请输入销售姓名搜索"
            filterOption={false}
            onChange={onTransactionUserChange}
            allowClear
            notFoundContent={
              fetching ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin style={{ margin: 12 }} />
                </div>
              ) : (
                <Empty />
              )
            }
            onSearch={debouncedFetchOptions}
          /> */}
          <div>
            <Select
              placeholder="请选择交易类型"
              className={"optab_select"}
              options={options}
              key="2"
              notFoundContent={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={no}
                    alt=""
                    style={{
                      width: "120px",
                      height: "77px",
                      marginTop: "10px",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    暂无内容
                  </p>
                </div>
              }
              onChange={transactionTypeChange}
              allowClear
            ></Select>
          </div>
          <div>
            <RangePicker
              mode="date"
              className={"select_data"}
              onChange={transactionDateChange}
            />
          </div>
        </div>
    </div>
  );
};
export default inject("teamover")(observer(ContelSelect));
