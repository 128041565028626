import { useCallback, useEffect, useState } from "react";
import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../UIComponents/VideoConfigCard/VideoConfigCard";
import ChoseActionBar from "./ChoseActionBar/ChoseActionBar";
import CoverImgGroup from "../Components/CoverImgGroup/CoverImgGroup";
import {
  bindingCover,
  deleteCustomizeCover,
  getBindCover,
  getCoverList,
} from "@/services/mixCutDetail/mixCutDetail";
import useCoverAndBg from "@/useStore/mixCutDetail/useCoverAndBg";
import { observer } from "mobx-react";
import useRequest from "@/requestHooks/mixCutDetail/useRequest";
import MaterialFileModal from "@/components/business/MaterialFileModal/MaterialFIleModal";
import { toJS } from "mobx";
import allowTypeStore from "@/stores/allowTypeStore";
import { useParams } from "react-router-dom";
import TitleGroup from "../FontTitle/TitleGroup/TitleGroup";
import SelfCoverGroup from "./SelfCoverGroup";
import { Message } from "@arco-design/web-react";
import "./VideoCoverConfig.less";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import ChoseDetailPopover from "../ChoseDetailPopover/ChoseDetailPopover";
export interface IVideoCoverConfig {
  title: string;
  style?: React.CSSProperties;
}

const VideoCoverConfig: React.FC<IVideoCoverConfig> = observer((props) => {
  const { title, style } = props;
  const { id } = useParams();
  const { cover, handleCoverChange } = useCoverAndBg();
  const { data } = useRequest({ request: getCoverList });
  const [uploadVisible, setUploadVisible] = useState(false);
  const [selfCoverData, setSelfCoverData] = useState([]);
  const [titleGroupIndex, setTitleGroupIndex] = useState(0);

  const toSelfCoverDataGetItem = (id: number) => {
    const item = selfCoverData.find((item) => Number(item.id) === id);
    return item;
  };

  const toPublicDataGetItem = (id: number) => {
    const item = data.find((item) => Number(item.id) === id);
    return item;
  };

  const handleImgCoverCardCheckStateChange = (e: boolean, id: number) => {
    if (!data || data.length === 0) return;
    if (e) {
      const item =
        titleGroupIndex === 1
          ? toPublicDataGetItem(id)
          : toSelfCoverDataGetItem(id);
      handleCoverChange([...cover, item]);
    } else {
      handleCoverChange(cover.filter((item) => item.id !== id));
    }
  };

  const init = async () => {
    const selfCover = await getBindCover(id);
    setSelfCoverData(selfCover);
  };
  useEffect(() => {
    init();
  }, []);

  const handleMaterialFileModalOkClick = async (e: IMaterialDetail[]) => {
    if (e.length > 0) {
      try {
        const getUnBindIds = e.filter(
          (item) =>
            cover.findIndex((step) => Number(step.id) === Number(item.id)) < 0,
        );
        await bindingCover(
          id,
          getUnBindIds.map((item) => Number(item.id)),
        );
        const selfCover = await getBindCover(id);
        setSelfCoverData(selfCover);
        setUploadVisible(false);
      } catch (e) {
        console.log(e);
        // Message.error("添加失败，请刷新后重试！");
      }
    }
  };

  const deleteSelfCover = async (e: number) => {
    try {
      await deleteCustomizeCover(id, e);
      const selfCover = await getBindCover(id);
      setSelfCoverData(selfCover);
      Message.success("删除自定义图片成功!");
      handleCoverChange(cover.filter((item) => Number(item.id) !== Number(e)));
    } catch (e) {
      console.log(e);
      Message.error("删除失败！");
    }
  };

  return (
    <VideoConfigCard style={style}>
      <VideoConfigCardHeader
        headerLeft={title}
        headerRight={
          <ChoseDetailPopover
            showField={"name"}
            handleTagCloseBtnClick={(e) => {
              handleImgCoverCardCheckStateChange(false, Number(e));
            }}
            handleClearBtnClick={() => {
              handleCoverChange([]);
            }}
            opeatorFiled={"id"}
            data={cover}
            title={"封面配置"}
          />
      }
      />
      <VideoConfigCardContainer>
        <>
          <div className="chose-action-bar">
            <TitleGroup
              handleActiveIndexChange={(e) => {
                setTitleGroupIndex(e);
              }}
              // data={[{ title: "官方封面图" }, { title: "自定义" }]}
              data={[{ title: "自定义" }]}
              active={titleGroupIndex}></TitleGroup>
          </div>
          {titleGroupIndex === 1 ? (
            <CoverImgGroup
              coverList={data}
              handleChoseImgChange={(state, id) => {
                handleImgCoverCardCheckStateChange(state, id);
              }}
              collection={cover}
            />
          ) : (
            <>
              <SelfCoverGroup
                handleDeleteItem={(e) => {
                  deleteSelfCover(e);
                }}
                handleUploadBlockBtnClick={() => {
                  setUploadVisible(true);
                }}
                handleChoseImgChange={(state, id) => {
                  handleImgCoverCardCheckStateChange(state, Number(id));
                }}
                data={selfCoverData}
                collection={cover}
              />
            </>
          )}
        </>
      </VideoConfigCardContainer>
      {uploadVisible ? (
        <MaterialFileModal
          defalutValue={'material'}
          handleOkBtnClick={(e) => {
            handleMaterialFileModalOkClick(e);
          }}
          maxCollectionLength={15}
          onCancel={() => {
            setUploadVisible(false);
          }}
          defaultCollection={
            cover
              .filter((item) => (item as any).play_info)
              .map((item) => item.id) as any
          }
          importCollection={selfCoverData?.length > 0 && selfCoverData.map(item => item.id)}
          visible={uploadVisible}
          allowMetarial={[3, 4]}
          allowType={toJS(
            allowTypeStore.getAllPictureTypeString(),
          )}></MaterialFileModal>
      ) : (
        <></>
      )}
    </VideoConfigCard>
  );
});
export default VideoCoverConfig;
