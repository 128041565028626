import { useEffect, useState, useCallback } from "react";
import { SketchPicker } from "react-color";
import "./ColorLineImgBlock.less";
import { Tooltip } from "@arco-design/web-react";
import { recommend } from "@/pages/VideoClips/MixCutDetail/BgConfig/BgColorConfig/BgColorChoseContainer/BgColorChoseContainer";

interface IColorLineImgBlockProps {
  img: string;
  color?: string;
  style?: React.CSSProperties;
  handleColorChange: (color: string) => void;
  imgStyle?: React.CSSProperties;
  tip?: string;
}

const ColorLineImgBlock: React.FC<IColorLineImgBlockProps> = (props) => {
  const { img, color, style, handleColorChange, imgStyle, tip } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [pickColorPlanePosition, setPickColorPlanePosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  // 颜色选择器关闭逻辑
  const handleDocumentClick = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (
      !target.closest(".color-line-block") &&
      !target.closest(".color-picker-container")
    ) {
      setDisplayColorPicker(false);
    }
  }, []);

  // 颜色选择完成
  const handleChangeComplete = (color) => {
    handleColorChange(color.hex);
    setDisplayColorPicker(false);
  };

  // 打开颜色选择器
  const handleColorPickerOpen = (e: React.MouseEvent) => {
    if (!displayColorPicker) {
      const { clientX, clientY } = e;
      setPickColorPlanePosition({ x: clientX, y: clientY });
      setDisplayColorPicker(true);
    } else {
      setDisplayColorPicker(false);
    }
  };

  useEffect(() => {
    // 绑定点击事件，点击任意地方关闭颜色选择器
    if (displayColorPicker) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }
    // 清理事件监听器
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [displayColorPicker, handleDocumentClick]);

  return (
    <div
      style={style}
      className="color-line-block"
      onClick={handleColorPickerOpen}>
      <Tooltip content={tip}>
        <img src={img} style={imgStyle} alt="" />
        <div
          className="line"
          style={{
            background: color || "black",
            borderColor: color || "black",
          }}></div>
      </Tooltip>
      {displayColorPicker && (
        <div
          className="color-picker-container"
          style={{
            position: "fixed",
            zIndex: 200,
            top: pickColorPlanePosition.y - 200, // 调整颜色选择器的位置
            left: pickColorPlanePosition.x + 20,
          }}
          onClick={(e) => e.stopPropagation()}>
          <SketchPicker
            color={color}
            onChangeComplete={handleChangeComplete}
            presetColors={[
              {
                title: "白色",
                color: "#ffffff",
              },
              ...recommend.map((item) => {
                return { color: item };
              }),
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default ColorLineImgBlock;
