import { useEffect, useRef, useState } from "react";
import TitleGroup from "../../FontTitle/TitleGroup/TitleGroup";
import LineFontConfig from "../VoiceConfig/LineFontConfig/LineFontConfig";
import SmartSearch from "@/components/business/SmartSearch";
import Intelligent from "@/components/business/Intelligent";
import { Message } from "@arco-design/web-react";
import { observer } from "mobx-react";
import useTitle from "@/useStore/mixCutDetail/useTitle";
import { IconPlus } from "@arco-design/web-react/icon";
import {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../../UIComponents/VideoConfigCard/VideoConfigCard";
import deleteImg from "@/assets/cut-mix-detail/delete.png";
import { ITitle } from "@/stores/mixCutDetailStore/type/title";
import aiLineImg from "@/assets/cut-mix-detail/ai_line.png";
import MyEditor from "@/components/business/MyEditor";
import usePreviewConfig from "@/useStore/preview/usePreview";
import { useEditorHelper } from "@/components/business/MyEditor/helper";
import "./GlobalTitle.less";

const GlobalTitle = observer(() => {
  const {
    titleList: globalTitle,
    handleTitleItemAdd,
    handleTitleItemRemove,
    handleTitleItemChange,
    handelTitleItemAddItem,
    handleTitleItemSimpleRemove,
  } = useTitle();
  const { handlePreviewTitleChange, handleClipIdChange } = usePreviewConfig();
  const [titleActiveIndex, setTitleActiveIndex] = useState(0);
  const [titleItemCurrentIndex, setTitleItemCurrentIndex] = useState(0);
  const [aiSearchState, setAiSearchState] = useState<boolean[]>([]);
  const [intelligenceState, setIntelligenceState] = useState<boolean>(false);
  const [isIntelligenceTypo, setIsIntelligenceTypo] = useState(true);
  const [selectPostion, setSelectPosition] = useState({
    from: 0,
    to: 0,
  });
  const editorRef = useRef<any[] | null>([]);

  // smart所用
  const [smartValue, setSmartValue] = useState("");
  const [smartStatus, setSmartStatus] = useState("");
  const [sendSmartStatus, setSendSmartStatus] = useState("");
  const [itelligentIsExecution, setItelligentIsExecution] = useState(true);
  const [selectInfo, setSelectInfo] = useState({
    index: -1,
    range: {
      from: -1,
      to: -1,
    },
  });

  useEffect(() => {
    const editor = (editorRef?.current || [])[titleItemCurrentIndex]?.editor;
    const [helper] = useEditorHelper(editor);
    setSelectInfo({ ...selectInfo, range: helper.getSelectionSSML() });
  }, [titleItemCurrentIndex]);

  const handleClickInterlligent = (opt) => {
    const edit = editorRef.current[titleItemCurrentIndex].editor;
    const [helper] = useEditorHelper(edit);
    const { from, to } = helper.getSelectionSSML();
    console.log(from, to);
    switch (opt.type) {
      case "change":
        if (from === to) {
          edit.commands.focus("all");
          edit.commands.insertContent(opt.text);
        } else {
          edit.commands.insertContentAt(selectInfo.range, opt.text, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: "full",
            },
          });
        }
        Message.success("替换成功");
        break;
      case "copy":
        navigator.clipboard.writeText(opt.text);
        Message.success("复制成功");
        break;
      case "splice":
        edit.commands.insertContentAt(selectInfo.range, opt.text, {
          updateSelection: true,
          parseOptions: {
            preserveWhitespace: "full",
          },
        });
        Message.success("添加成功");
        break;
      default:
        break;
    }
  };

  const toGlobalTitleGroupGetTitle = (data: ITitle) => {
    return data.map((_, index) => {
      return { title: "标题组" + (index + 1) };
    });
  };

  const handleTitleGroupChange = (index) => {
    setTitleActiveIndex(index);
    setTitleItemCurrentIndex(0);
    setItelligentIsExecution(true);
    setIntelligenceState(false);
    setAiSearchState(aiSearchState.map((item) => false));
    handlePreviewTitleChange(globalTitle[index]);
  };

  const handleDeleteBtnClick = (e) => {
    if (globalTitle.length === 1) {
      Message.error("必须保持有一个台词组！");
      return;
    }
    if (e === 0 && titleActiveIndex === 0) {
      handlePreviewTitleChange(
        (globalTitle || [])[titleActiveIndex + 1] || null,
      );
      handleClipIdChange(null);
    }
    if (titleActiveIndex === e) {
      setTitleActiveIndex(0);
    }
    if (titleActiveIndex > e) {
      setTitleActiveIndex(titleActiveIndex - 1);
    }
    handleTitleItemRemove(e);
  };
  useEffect(() => {
    handlePreviewTitleChange((globalTitle || [])[titleActiveIndex]);
    handleClipIdChange(null);
  }, [titleActiveIndex]);

  return (
    <div className="global-title-container-wrapper">
      <div className="title-group-wrapper">
        <TitleGroup
          maxLength={15}
          active={titleActiveIndex}
          style={{ marginTop: 12, marginBottom: 10 }}
          titleCopy={"添加标题"}
          isEdit={true}
          data={toGlobalTitleGroupGetTitle(globalTitle)}
          handleActiveIndexChange={handleTitleGroupChange}
          handleDeleteBtnClick={handleDeleteBtnClick}
          defaultValue={"标题一"}
          handleAddBtnClick={() => {
            handleTitleItemAdd();
          }}
        />
      </div>
      <div className="global-title-wrapper">
        {(globalTitle[titleActiveIndex]?.content || []).map((item, index) => {
          return (
            <div
              key={item.id}
              className={`${index === titleItemCurrentIndex ? "title-group-current-active" : ""}`}>
              <VideoConfigCardHeader
                headerLeft={`文本标题${index + 1}`}
                headerRight={
                  <div
                    className="delete-title-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleTitleItemSimpleRemove(titleActiveIndex, index);
                    }}>
                    <img src={deleteImg} style={{ width: 12 }} alt="" />
                  </div>
                }
              />
              <VideoConfigCardContainer
                style={{ marginBottom: "12px", paddingBottom: "4px" }}>
                <div
                  style={{ position: "relative" }}
                  className="editor-wrapper-title">
                  <MyEditor
                    ref={(ref) => {
                      editorRef.current[index] = ref;
                    }}
                    limit={20}
                    content={
                      item._text && item._text.length > 0
                        ? item._text
                        : [
                            {
                              type: "paragraph",
                              content: [
                                {
                                  type: "text",
                                  text: "",
                                },
                              ],
                            },
                          ]
                    }
                    placeholder={"请输入文字标题"}
                    type={"caption"}
                    isCharacterLimit={false}
                    handlefocus={() => {
                      setTitleItemCurrentIndex(index);
                      handleClipIdChange(
                        globalTitle[titleActiveIndex].content[index].id,
                      );
                    }}
                    handleUpdate={(e) => {
                      const data = editorRef.current[index].editor
                        .getText()
                        .replace("\n", " ");
                      handleTitleItemChange(
                        titleActiveIndex,
                        titleItemCurrentIndex,
                        {
                          ...item,
                          text: data,
                          _text: e,
                        },
                      );
                    }}
                    selected={({ value, range }) => {
                      setSmartValue(
                        value ||
                          editorRef.current[titleActiveIndex].editor
                            .getText()
                            .replace(/\n\n\n\n/g, "\n")
                            .slice(1),
                      );
                      setSelectInfo({
                        index: -1,
                        range,
                      });
                    }}
                    setEditPosition={(ops) => {
                      console.log(
                        "ops",
                        ops,
                        "----------------------------------------------------",
                      );
                    }}
                    selectInfo={selectInfo}
                  />
                  <div className="voice-container-bar-title">
                    <div
                      className="insert-opeator"
                      style={{ position: "relative" }}>
                      <img
                        src={aiLineImg}
                        alt=""
                        style={{ marginLeft: 6 }}
                        className="ai-line"
                        onClick={() => {
                          const newAiSearchState = [...aiSearchState].map(
                            () => false,
                          );
                          newAiSearchState[index] = true;
                          setAiSearchState([...newAiSearchState]);
                          setIntelligenceState(false);
                        }}
                      />
                      {aiSearchState[index] ? (
                        <SmartSearch
                          styles={{ width: "468px", marginLeft: "30px" }}
                          handleKeyDown={(e) => {
                            if (e.code === "27" || e.code === "Escape") {
                              const newAiSearchState = [...aiSearchState];
                              newAiSearchState[index] = false;
                              setAiSearchState([...newAiSearchState]);
                            }
                          }}
                          editValue={smartValue}
                          isEnd={isIntelligenceTypo}
                          placeholder={"帮我写..."}
                          handelClickBtn={(value: string, type: string) => {
                            setSmartStatus(type);
                            setSendSmartStatus(value);
                            setIntelligenceState(true);
                          }}
                        />
                      ) : null}
                    </div>
                    {intelligenceState ? (
                      <Intelligent
                        style={{
                          width: "405px",
                          position: "fixed",
                          right: 0,
                          bottom: 0,
                          top: 73,
                          height: "calc(100vh - 120px)",
                          zIndex: 1000,
                        }}
                        defaultStatus={smartStatus}
                        value={sendSmartStatus}
                        isInExecution={setItelligentIsExecution}
                        handleOnChange={(e) => {
                          handleClickInterlligent(e);
                        }}
                        handleCloseBtnClick={() => {
                          setSendSmartStatus("");
                          setIntelligenceState(false);
                          setItelligentIsExecution(true);
                          setAiSearchState(aiSearchState.map((item) => false));
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </VideoConfigCardContainer>
            </div>
          );
        })}
        <div className="add-button-wrapper">
          <div
            className="add-button"
            onClick={() => {
              handelTitleItemAddItem(titleActiveIndex);
              setAiSearchState([...aiSearchState, false]);
              setItelligentIsExecution(true);
              setIntelligenceState(false);
            }}>
            <IconPlus />
            添加标题
          </div>
        </div>
      </div>
      <div className="font-wrapper" style={{ marginTop: 20 }}>
        <LineFontConfig
          global={true}
          data={
            (globalTitle[titleActiveIndex]?.content || [])[
              titleItemCurrentIndex
            ]?.text_setting
          }
          handleTextSettingChange={(e) => {
            const newItemTextSetting =
              globalTitle[titleActiveIndex].content[titleItemCurrentIndex]
                .text_setting;
            let x = 0;
            switch (e.alignment) {
              case "left":
                x = 0;
                break;
              case "center":
                x = 0.5;
                break;
              case "right":
                x = 1;
                break;
            }
            handleTitleItemChange(titleActiveIndex, titleItemCurrentIndex, {
              ...globalTitle[titleActiveIndex].content[titleItemCurrentIndex],
              text_setting: { ...newItemTextSetting, ...e, x },
            });
          }}
        />
      </div>
    </div>
  );
});

export default GlobalTitle;
