import "./TransitionCard.less";
import { memo } from "react";
export interface ITransitionCard {
  id?: string;
  url: string;
  name: string;
  style?: React.CSSProperties;
  onClick: () => void;
}

const TransitionCard: React.FC<ITransitionCard> = memo((props) => {
  const { url, style, onClick } = props;
  return (
    <div
      className="transition-card-wrapper"
      style={style}
      onClick={() => {
        onClick();
      }}>
      <div className="transition-card">
        <img src={url} alt="" />
      </div>
    </div>
  );
});

export default TransitionCard;
