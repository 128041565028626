import "./ChoiceItem.less";

interface IChoiceItemProps {
  value: string;
  tip?: string;
  active: boolean;
  style?: React.CSSProperties;
  className?: string;
  onClick: () => void;
}

const ChoiceItem: React.FC<IChoiceItemProps> = (props) => {
  const { value, active, tip, style, className, onClick } = props;
  return (
    <div
      onClick={() => {
        onClick();
      }}
      style={style}
      className={`${active ? "active" : ""} componse-modal-form-choice-item ${className}`}>
      {value} {tip ? <span>{tip}</span> : <></>}
    </div>
  );
};

export default ChoiceItem;
