import { Fragment } from "react/jsx-runtime";
import MusicCard from "../MusicCard/MusicCard";
import clearImg from "@/assets/create-space/script-clear.png";
import useCoverAndBg from "@/useStore/mixCutDetail/useCoverAndBg";
import { IMusicResponse } from "@/services/mixCutDetail/mixCutDetail";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import MusicPlayer from "./MusicPlayer/MusicPlayer";
import { Spin } from "@arco-design/web-react";
import "./BgMusicContainer.less";
export interface BgMusicContainerProps {
  style?: React.CSSProperties;
  loading: boolean;
  data: IMusicResponse[];
  handleMusicCollectBtcClick: (id: number) => void;
  handleCancelCollectBtnClick: (id: number) => void;
}

const BgMusicContainer: React.FC<BgMusicContainerProps> = observer((props) => {
  const {
    style,
    data,
    loading,
    handleMusicCollectBtcClick,
    handleCancelCollectBtnClick,
  } = props;
  const { bgAudio, handleBgAudiosChange } = useCoverAndBg();
  const [currentId, setCurrentId] = useState<number>(null);
  const [currentMusic, setCurrentMusic] = useState<{
    src: string;
    title: string;
    isPlay: boolean;
    duration: number;
  }>(null);
  const handleCheckStateChange = (e: boolean, id: number) => {
    if (e) {
      const item = data.find((item) => item.id === id);
      handleBgAudiosChange([
        ...bgAudio,
        { ...item, duration: item.duration * 1000 },
      ]);
    } else {
      const index = bgAudio.findIndex((item) => item.id === id);
      bgAudio.splice(index, 1);
      handleBgAudiosChange([...bgAudio]);
    }
  };

  useEffect(() => {
    setCurrentMusic(null);
  }, [data]);

  return (
    <div className="bg-music-container" style={style}>
      {/*<div className="bg-music-search-bar">*/}
      {/*  <SmallSearch value={keyWord} onChange={setKeyWord} />*/}
      {/*  <ChoseDetailPopover*/}
      {/*    showField={"title"}*/}
      {/*    handleTagCloseBtnClick={(e) => {*/}
      {/*      handleCheckStateChange(false, e as number);*/}
      {/*    }}*/}
      {/*    handleClearBtnClick={() => {*/}
      {/*      handleBgAudiosChange([]);*/}
      {/*    }}*/}
      {/*    opeatorFiled={"id"}*/}
      {/*    data={bgAudio}*/}
      {/*    title={"背景音乐"}*/}
      {/*  />*/}
      {/*</div>*/}
      {!data || data.length === 0 ? (
        <div className="empyt">
          <img src={clearImg} alt="" />
          <p>暂无数据</p>
        </div>
      ) : (
        <>
          {/* <div className="chose-wrapper">已选择：{bgAudio.length}</div> */}

          <div className="music-card-wrapper">
            <Spin
              loading={loading}
              style={{ width: "100%", height: "100%" }}
              className={"music-card-container"}>
              {data.map((item) => {
                return (
                  <Fragment key={item.author_name + item.title + item.id}>
                    <MusicCard
                      handleCheckMusic={(e) => {
                        handleCheckStateChange(e, item.id);
                      }}
                      currentId={currentId}
                      handleCancelCollectBtnClick={() => {
                        handleCancelCollectBtnClick(item.id);
                      }}
                      hanldeCollectBtnClick={() => {
                        handleMusicCollectBtcClick(item.id);
                      }}
                      onClick={() => {
                        setCurrentMusic({
                          title: item.author_name + " - " + item.title,
                          src: item.url,
                          duration: item.duration,
                          isPlay: true,
                        });
                        setCurrentId(item.id);
                      }}
                      handleCheckStateChange={(e) => {
                        handleCheckStateChange(e, item.id);
                      }}
                      style={{
                        width: "calc(50% - 10px)",
                        marginRight: 9,
                        marginBottom: 9,
                        position: "sticky",
                      }}
                      checkState={
                        (bgAudio || []).findIndex(
                          (step) => item?.id === step?.id,
                        ) > -1
                      }
                      data={item}
                    />
                  </Fragment>
                );
              })}
            </Spin>
          </div>
        </>
      )}
      {currentMusic?.isPlay ? (
        <MusicPlayer
          handleCloseBtnClick={() => {
            setCurrentMusic(null);
            setCurrentId(null);
          }}
          style={{ position: "absolute", bottom: 0, right: 0, left: 74 }}
          duration={currentMusic.duration}
          src={currentMusic.src}
          title={currentMusic.title}
        />
      ) : (
        <></>
      )}
    </div>
  );
});

export default BgMusicContainer;
