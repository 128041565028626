import React, { useEffect, useRef, useState } from "react";
import { Pagination, Table, TableColumnProps } from "@arco-design/web-react";
import { inject, observer } from "mobx-react";
import Nodata from "../componts/Nodata";
import { equity_flow_api, transaction_record_api } from "@/services/temeover";
import dayjs from 'dayjs';
enum Etrade {
  '权益增加' = 1,
  '权益扣除',
  '失败返还'
}
enum Eapp {
  '订单' = 1,
  '试用' = 2,
  '订单退款' = 3,
  '视频解析' = 101,
  '智能混剪' = 102,
  '团队成员席位' = 103
}
const columns: TableColumnProps[] = [
  {
    title: "编号",
    dataIndex: "trans_num",
  },
  {
    title: "交易类型",
    dataIndex: "transaction_type",
    render: (text, b, c) => {
      return (
        <span
          className={
            text == "2" ? "opdatatitle opred" : "opdatatitle opgren"
          }>
          {text == '1' ? '购买权益': '平台赠送'}
        </span>
      );
    },
  },
  {
    title: "权益名称",
    dataIndex: "benefit_name",
  },
  {
    title: "销售人员",
    dataIndex: "sales_agent_name",
  },
  {
    title: "交易金额",
    dataIndex: "amount",
    render: (text) => {
      return text ? `${text / 100}元`: null
    }
  },
  {
    title: "交易方式",
    dataIndex: "pay_type",
    render: (text) => {
      return text == '1' ? '银行对公': '扫码支付'
    }
  },
  {
    title: "交易时间",
    dataIndex: "created_at",
    render: (text) => {
      return dayjs(text * 1000).format('YYYY-MM-DD HH:mm:ss')
    }
  },
];
const columnson: TableColumnProps[] = [
  {
    title: "编号",
    dataIndex: "trans_num",
  },
  {
    title: "交易类型",
    dataIndex: "trande_type",
    render: (a, b, c) => {
      console.log(a);
      return (
        <span
          className={
            a == 3 ? "opdatatitle opred" : "opdatatitle opgren"
          }>
          {Etrade[a]}
        </span>
      );
    },
  },
  {
    title: "应用类型",
    dataIndex: "app_type",
    render: (text) => {
      return Eapp[text]
    }
  },
  {
    title: "交易明细",
    dataIndex: "change_value",
    render: (text) => {
      return text > 0 ? `+${text}` : text;
    }
  },
  {
    title: "剩余条数",
    dataIndex: "left_value",
  },
  {
    title: "操作账号",
    dataIndex: "member_name",
  },
  {
    title: "交易时间",
    dataIndex: "created_at",
    render: (text) => {
      return dayjs(text * 1000).format('YYYY-MM-DD HH:mm:ss')
    }
  },
];
const TableSelect = (props) => {
  const firstRender = useRef(true);
  const { teamover, team } = props;
  const [total, setTotal] = useState(0);
  const [a, seta] = useState([]);
  const [n, setn] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [current, setCurrent] = useState(1);
  const onChangeTable = (current: number, pageSize: number) => {
    console.log(current, pageSize);
    setPageSize(pageSize);
    setCurrent(current);
    if (teamover.dataactive == "activeqy") {
      getEquityList({page: current, page_size: pageSize, ...teamover.equitySearch})
    } else {
      getTransactionRecord({page: current, page_size: pageSize, team_id: team.team_id, ...teamover.transactionSearch})
    }
  };
  const getEquityList = (payload) => {
    equity_flow_api(payload).then(res => {
      const array = res?.data?.items || [];
      seta(array.map((i, index) => {
        i.key = index
        return i
      }));
      setTotal(res?.data?.total || 0)
    }).finally(() => {
      firstRender.current = false;
    })
  }
  const getTransactionRecord = (payload) => {
    transaction_record_api(payload).then(res => {
      const array = res?.data?.transaction_record?.items || [];
      seta(array.map((i, index) => {
        i.key = index
        return i
      }));
      setTotal(res?.data?.transaction_record?.total || 0)
    })
  }
  useEffect(() => {
    if (!team.team_id) return;
    if (teamover.dataactive == "activeqy") {
      // seta(teamover.equityList);
      setn(columnson);
      getEquityList({page: 1, page_size: pageSize, ...teamover.equitySearch})
    } else {
      setn(columns);
      getTransactionRecord({page: 1, page_size: pageSize, team_id: team.team_id, ...teamover.transactionSearch})
    }
  }, [teamover.dataactive, team.team_id]);
  useEffect(() => {
    if (firstRender.current){
      return;
    }
    setCurrent(1)
    getEquityList({page: 1, page_size: pageSize, ...teamover.equitySearch})
  }, [teamover.equitySearch])
  useEffect(() => {
    if (firstRender.current){
      return;
    }
    setCurrent(1)
    getTransactionRecord({page: 1, page_size: pageSize, team_id: team.team_id, ...teamover.transactionSearch})
  }, [teamover.transactionSearch])
  return (
    <div className="selccompont_tabdata">
      <Table
        hover={false}
        tableLayoutFixed={true}
        border={false}
        columns={n}
        data={a}
        pagination={false}
        noDataElement={<Nodata></Nodata>}
      />
      {/* <Pagination
        total={50}
        showTotal={(total) => `共 ${total}条`}
        showJumper
        sizeCanChange
        className={"tab-pages"}
      /> */}
      <Pagination
        showTotal={(total) => `共 ${total}条`} //是否显示数据总数
        total={total} // 数据总数
        // hideOnSinglePage  // 是否在一页的时候 隐藏
        showJumper //	是否显示快速跳转到某页，在 simple 模式下默认为 true	boolean
        sizeCanChange //	是否可以改变每页条数
        onChange={(current, pageSize) => {
          onChangeTable(current, pageSize);
        }}
        className={"tab-pages"}
      />
    </div>
  );
};
export default inject("teamover", "team")(observer(TableSelect));
