import { IconClose } from "@arco-design/web-react/icon";
import "./TitleGroupTagItem.less";
import {Tooltip, Popconfirm} from "@arco-design/web-react";
import deleteImg from "@/assets/cut-mix-detail/delete.png";
import React from "react";

export interface ITitleGroupTagItem {
  closeState?: boolean;
  active: boolean;
  title: string;
  onClick: () => void;
  handleDeleteBtnClick: () => void;
}

const TitleGroupTagItem: React.FC<ITitleGroupTagItem> = (props) => {
  const { title, active, onClick, handleDeleteBtnClick, closeState } = props;
  return (
    <div
      onClick={onClick}
      className={`${active ? "active" : ""}  title-group-tag-item`}>
      <span>{title}</span>
      {closeState ? (
        <Popconfirm
          focusLock
          title="删除提示！"
          content="确认删除台词组？"
          onOk={(e) => {
            e.stopPropagation();
            handleDeleteBtnClick();
          }}
          onCancel={(e) => {
            e.stopPropagation();
            console.log("取消");
          }}>
          <IconClose
            className="icon-close"
          />
        </Popconfirm>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TitleGroupTagItem;
