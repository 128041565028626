import {
  Button,
  Form,
  Input,
  Modal,
  ModalProps,
  Popover,
  Tooltip,
  TreeSelect,
} from "@arco-design/web-react";
import { useEffect, useState } from "react";
import ChoiceGroup from "./ChoiceGroup/ChoiceGroup";
import { debounce } from "lodash";
import "./ComponseModal.less";
import useAllProjectAndPorfolioFolder from "@/requestHooks/createSpace/useAllProjectAndPorflioFolder";
import { max } from "moment";
const proportionList = [
  {
    value: "720p",
    label: "720P",
  },
  {
    value: "1080p",
    label: "1080P",
  },
  {
    value: "2k",
    label: "2k(高)",
    tip: "消耗2条视频条数",
  },
];

const fpsList = [
  {
    value: 25,
    label: "25fps",
  },
  {
    value: 30,
    label: "30fps",
  },
  {
    value: 50,
    label: "50fps",
  },
  {
    value: 60,
    label: "60fps",
  },
];

const formInit = {
  proportion: "1080p",
  bitrate: 12000,
  fps: 30,
};

export interface IFormData {
  /**
   * 比例
   */
  proportion: string;
  /**
   * 码率
   */
  bitrate: number;
  /**
   * fps
   */
  fps: number;
  type_id: number;
  // parent_id?: number;
  // project_id?: number;
}

export interface ISubmitData extends Omit<IFormData, "type_id"> {
  project_id: number;
  parent_id: number;
}

export interface IComponseModalProps extends Omit<ModalProps, "onOk"> {
  onOk: (e: ISubmitData) => void;
  videoNumber: number;
  mode?: "white" | "black";
  saveLoading?: boolean;
  count?: number;
}

const ComponseModal: React.FC<IComponseModalProps> = (props) => {
  const {
    mode = "white",
    videoNumber,
    saveLoading = false,
    onOk,
    count,
    ...res
  } = props;
  const [componseNumber, setComponseNumber] = useState<number>(null);
  const { treeData, toFolderIdGetProjectId } = useAllProjectAndPorfolioFolder();
  const [saveType, setSaveType] = useState<"project" | "folder">(null);
  const [form] = Form.useForm<IFormData>();

  const handleComponseClick = () => {
    submitFormData();
  };

  const hanldeFolderItemClick = debounce(() => {
    submitFormData();
  }, 300);

  const submitFormData = async () => {
    try {
      const result = await form.validate();
      if (!result.type_id) {
        form.setFields({
          type_id: {
            error: {
              message: "请选择需要保存的位置",
            },
          },
        });
      }
      let project_id: number = null;
      let parent_id: number = null;
      if (saveType === "folder") {
        project_id = (result.type_id as any).split(":")[0];
        parent_id = (result.type_id as any).split(":")[1];
      } else {
        project_id = (result.type_id as any).split(":")[0];
        parent_id = 0;
      }
      const submitResultData: ISubmitData = {
        project_id,
        parent_id,
        fps: result.fps,
        bitrate: result.bitrate,
        proportion: result.proportion,
      };
      console.log(submitResultData);
      onOk(submitResultData);
    } catch (e) {
      console.log(e);
    }
  };

  const formChange = (e: Partial<IFormData>) => {
    const [key, value] = Object.entries(e)[0];
    if (key === "proportion" && value === "2k") {
      setComponseNumber(videoNumber * 2);
      return;
    }
    if (key === "proportion") {
      setComponseNumber(videoNumber);
      return;
    }
  };
  useEffect(() => {
    form.setFieldsValue(formInit);
    setComponseNumber(videoNumber);
  }, [videoNumber]);

  return (
    <Modal
      {...res}
      title={"合成视频"}
      className={`${res.className} componse-modal-wrapper-${mode}`}
      footer={
        <div className="componse-modal-footer">
          <div className="tip">
            已选择<span>{videoNumber}</span>,合成成功后将扣除
            <span className="componse-wrappper">{componseNumber}</span>
            条视频条数
          </div>
          <div className="button-opeartor">
            <Button
              className="cancel-btn"
              style={{ marginRight: 8 }}
              onClick={() => {
                res.onCancel();
              }}>
              取消
            </Button>
            {count === 0 || (count && componseNumber > count) ? (
              <Tooltip
                content={`权益不足！本次合成将扣除${componseNumber}条，您目前仅剩余${count}条视频数`}>
                <Button
                  className="save-btn"
                  type="primary"
                  disabled
                  loading={saveLoading}
                  onClick={() => {
                    hanldeFolderItemClick();
                  }}>
                  保存
                </Button>
              </Tooltip>
            ) : (
              <Button
                className="save-btn"
                type="primary"
                loading={saveLoading}
                onClick={() => {
                  hanldeFolderItemClick();
                }}>
                保存
              </Button>
            )}
          </div>
        </div>
      }>
      <Form<IFormData>
        layout="vertical"
        form={form}
        onChange={(e) => {
          formChange(e);
        }}>
        <Form.Item
          style={{ marginBottom: 0 }}
          label={"分辨率"}
          field={"proportion"}
          required={true}>
          <ChoiceGroup list={proportionList} />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 0 }}
          label={"码率"}
          field={"bitrate"}
          required={true}
          rules={[
            {
              required: true,
              type: "number",
              min: 6000,
              max: 20000,
              message: "码率的范围是6000~20000!",
            },
          ]}>
          <Input type={"number"} allowClear suffix={"kbps"} placeholder="" />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 0 }}
          label={"帧率"}
          field={"fps"}
          required={true}>
          <ChoiceGroup list={fpsList} />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 0 }}
          label={"保存位置"}
          field={"type_id"}
          required={true}>
          <TreeSelect
            showSearch
            placeholder="请选择..."
            treeData={treeData}
            onChange={(e, i) => {
              form.setFieldValue("type_id", e);
              setSaveType(i.trigger.dataRef.type);
            }}
            triggerProps={{
              popupStyle: {
                maxHeight: 300,
              },
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ComponseModal;
