import { Input, InputProps } from "@arco-design/web-react";
import { IconSearch } from "@arco-design/web-react/icon";
import "./SmallSearch.less";
import {useEffect, useRef, useState} from "react";
const SmallSearch: React.FC<InputProps> = (props) => {
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null)
  useEffect(() => {
    if (inputRef) {
      inputRef.current?.focus();
    }
  }, [focus]);
  return (
    <div className="small-search-wrapper bg-search" style={{ width: !focus ? '66px' : "200px" }}>
      {
        !focus && <div className="search-circle" onClick={() => {
          setFocus(true);
        }}>
              <IconSearch className="search-icon" style={{color: '#999999'}}/>
              搜索
          </div>
      }
      {
        focus && <Input
          onBlur={() => {
            if (!props.value) {
              setFocus(false);
            }
          }}
          ref={inputRef}
          {...props}
          allowClear
          className={"border-radius-search"}
          size="small"
          style={{ width: "100%" }}
          prefix={<IconSearch style={{ color: '#999999' }} />}
          placeholder="搜索歌曲名称/歌手"
          />
      }
    </div>
  );
};

export default SmallSearch;
