import { Tooltip } from "@arco-design/web-react";
import "./BgChoiceBlock.less";

export interface IBgChoiceBlock {
  isChoice?: boolean;
  img: string;
  style?: React.CSSProperties;
  onClick: () => void;
  tip: string;
}

const BgChoiceBlock: React.FC<IBgChoiceBlock> = (props) => {
  const { isChoice, img, style, onClick, tip } = props;
  return (
    <div
      onClick={onClick}
      className="choice-block"
      style={{ ...style, background: isChoice ? "#D8D8D8" : "" }}>
      <Tooltip content={tip}>
        <img src={img} alt="" />
      </Tooltip>
    </div>
  );
};

export default BgChoiceBlock;
