import { Button, Tooltip } from "@arco-design/web-react";
import ConfigTag from "./ConfigTag/ConfigTag";
import { useContext, useState } from "react";
import Title from "../UIComponents/Title/Title";
import MixCutConfigWrapper from "../UIComponents/MixCutConfigWrapper/MixCutConfigWrapper";
import preimg from "@/assets/cut-mix-detail/config-img/prev.png";
import nextImg from "@/assets/cut-mix-detail/config-img/next.png";
import disabledPreImg from "@/assets/cut-mix-detail/config-img/disabled-prev.png";
import disabledNextImg from "@/assets/cut-mix-detail/config-img/disabled-next.png";
import refeseImg from "@/assets/cut-mix-detail/refese.png";
import BgConfig from "../BgConfig/BgConfig";
import VideoCoverConfig from "../VideoCoverConfig/VideoCoverConfig";
import Trasnsition from "../Transition/Transition";
import FontTitle from "../FontTitle";
import { ICaption } from "@/stores/mixCutDetailStore/type/caption";
import CaptionConfig from "../CaptionConfig/CaptionConfig";
import VideoGlobalModal from "../TipModal/VideoGlobalModal";
import { mixCutDetailContext } from "../MixCutDetail";
import useVideoGroup from "@/useStore/mixCutDetail/useVideoGroup";
import GlobalCaption from "./GlobalCaption/GlobalCaption";
import GlobalTitle from "./GlobalTitle/GlobalTitle";
import GlobalTransition from "../Transition/GlobalTransition";
import { observer } from "mobx-react";
import useMixCutDetail from "@/useStore/mixCutDetail/useMixCutDetail";
import "./VideoConfig.less";

interface IVideoConfigProps {
  CationConfig: ICaption[][];
  videoConfigActiveIndex: number;
  global: boolean;
  handleVideoConfigActiveIndexChange: (e: number) => void;
  handleGlobalChange: (e: boolean) => void;
}

const VideoConfig: React.FC<IVideoConfigProps> = observer((props) => {
  const { videoConfigActiveIndex, global, handleVideoConfigActiveIndexChange } =
    props;
  const { videoGroupActive, setVideoGroupActive } =
    useContext(mixCutDetailContext);
  const { projectSences } = useVideoGroup();
  const [tipGlobalModal, setTipGlobalModal] = useState(false);
  const { toggleItemtoGlobal } = useMixCutDetail();

  return (
    <MixCutConfigWrapper
      style={{
        boxSizing: "border-box",
        width: 510,
        minWidth: 510,
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        height: "calc(100vh - 84px)",
        paddingTop: "0px",
        overflow: "auto",
      }}>
      <div
        className="video-config-header"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 101,
          background: "white",
          paddingTop: "12px",
        }}>
        <div className="config-header">
          <Title
            title={
              global
                ? "全局镜头配置"
                : (projectSences || [])[videoGroupActive]?.name
            }
            desc={"设置视频各分镜的配置属性"}
          />
          {!global ? (
            <div className="introduce">
              <div className="opeator">
                <Tooltip content="上一个镜头组">
                  <Button
                    onClick={() => {
                      setVideoGroupActive(videoGroupActive - 1);
                    }}
                    disabled={videoGroupActive === 0}
                    style={{ marginRight: 6 }}
                    icon={
                      videoGroupActive === 0 ? (
                        <img
                          src={disabledPreImg}
                          style={{
                            width: 16,
                            height: 16,
                            position: "relative",
                            top: "3px",
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={preimg}
                          style={{
                            width: 16,
                            height: 16,
                            position: "relative",
                            top: "3px",
                          }}
                        />
                      )
                    }
                    size="small"></Button>
                </Tooltip>
                <Tooltip content="下一个镜头组">
                  <Button
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setVideoGroupActive(videoGroupActive + 1);
                    }}
                    disabled={
                      videoGroupActive === (projectSences || []).length - 1
                    }
                    icon={
                      videoGroupActive === (projectSences || []).length - 1 ? (
                        <img
                          src={disabledNextImg}
                          style={{
                            width: 16,
                            height: 16,
                            position: "relative",
                            top: "3px",
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={nextImg}
                          style={{
                            width: 16,
                            height: 16,
                            position: "relative",
                            top: "3px",
                          }}
                        />
                      )
                    }
                    size="small"></Button>
                </Tooltip>
                <Button
                  style={{ marginRight: 6 }}
                  onClick={() => {
                    setTipGlobalModal(true);
                  }}
                  icon={
                    <img
                      src={refeseImg}
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: 4,
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  }
                  size="small">
                  全局配置
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>
          <ConfigTag
            videoActiveIndex={videoGroupActive}
            global={global}
            activeKey={videoConfigActiveIndex}
            handleActiveIndexChange={(e) => {
              handleVideoConfigActiveIndexChange(e);
            }}
          />
        </div>
      </div>

      {videoConfigActiveIndex === 0 ? (
        !global ? (
          <CaptionConfig />
        ) : (
          <GlobalCaption />
        )
      ) : (
        <></>
      )}
      {videoConfigActiveIndex === 1 ? (
        !global ? (
          <FontTitle style={{ marginTop: 10 }} />
        ) : (
          <GlobalTitle />
        )
      ) : (
        <></>
      )}
      {videoConfigActiveIndex === 2 ? (
        !global ? (
          <Trasnsition />
        ) : (
          <GlobalTransition />
        )
      ) : (
        <></>
      )}
      {videoConfigActiveIndex === 3 ? (
        <VideoCoverConfig title={"封面配置"} />
      ) : (
        <></>
      )}
      {videoConfigActiveIndex === 4 ? <BgConfig /> : <></>}
      <VideoGlobalModal
        title={"切换全局模式警告"}
        visible={tipGlobalModal}
        content={
          "切换到全局配置模式，分镜的台词组，标题，已经转场模式全部丢失，是否继续?"
        }
        onCancel={() => setTipGlobalModal(false)}
        onOk={() => {
          setTipGlobalModal(false);
          toggleItemtoGlobal();
          setVideoGroupActive(null);
        }}
      />
    </MixCutConfigWrapper>
  );
});

export default VideoConfig;
