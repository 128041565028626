import {
  Checkbox,
  Input,
  InputNumber,
  InputNumberProps,
} from "@arco-design/web-react";
import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../UIComponents/VideoConfigCard/VideoConfigCard";
import ChoseActionBar from "../VideoCoverConfig/ChoseActionBar/ChoseActionBar";
import TransitionGroup from "./TransitionGroup/TransitionGroup";
import { useContext, useEffect, useState } from "react";
import { mixCutDetailContext } from "../MixCutDetail";
import useTransition from "@/useStore/mixCutDetail/useTransition";
import { toJS } from "mobx";
import {
  IMixDetailTransitionRequest,
  getTransitionsList,
} from "@/services/mixCutDetail/mixCutDetail";
import {
  ITransitionEffectItem,
  ITransitionItem,
} from "@/stores/mixCutDetailStore/type/transition";
import useRequest from "@/requestHooks/mixCutDetail/useRequest";
import "./Transition.less";
import ChoseDetailPopover from "../ChoseDetailPopover/ChoseDetailPopover";
const Trasnsition = () => {
  const { data } = useRequest({
    request: getTransitionsList,
  });
  const {
    transition,
    handleTransitionItemChange,
    handleTransitionItemEffectsRemove,
    handleTransitionItemIsGlobaltoTrue,
    handleTransitionItemClear,
  } = useTransition();
  const { videoGroupActive: videoGroupTransitionId } =
    useContext(mixCutDetailContext);
  const [choseList, setChoseList] = useState<{ name: string; id: number }[]>(
    [],
  );
  useEffect(() => {
    if (
      transition &&
      Object.keys(transition).length > 0 &&
      data &&
      data.length > 0
    ) {
      setChoseList(
        getCheckedIdList(
          transition[(videoGroupTransitionId - 1).toString()],
          data,
        ),
      );
    }
  }, [data, transition, videoGroupTransitionId]);

  const getCheckedIdList = (
    transitionItem: ITransitionItem,
    data: IMixDetailTransitionRequest[],
  ) => {
    if (!transitionItem || Object.keys(transitionItem).length === 0) {
      return [];
    }
    const effects = toJS(
      transitionItem.effects || [],
    ) as ITransitionEffectItem[];
    const idList = (data || [])
      .filter((item) => {
        return effects.some((step) => {
          return (
            item.content.sub_type === step.sub_type &&
            item.content.type === step.type
          );
        });
      })
      .map((item) => {
        return { id: item.id, name: item.name };
      });
    return idList;
  };

  const toIdGetContent = (id: number, data: IMixDetailTransitionRequest[]) => {
    const item = data.find((item) => item.id === id).content;
    return { ...item };
  };

  const handleTransitionCardCheckboxChange = (state: boolean, id: number) => {
    const key = (videoGroupTransitionId - 1).toString();
    console.log(transition);
    const newTransitionItem = transition[key];
    if (state) {
      const item = data.find((item) => item.id === id);
      const updatedCheckTransition = [
        ...choseList,
        { name: item.name, id: item.id },
      ];
      if (!newTransitionItem?.effects) {
        newTransitionItem.effects = [];
      }
      (newTransitionItem.effects as ITransitionEffectItem[]).push(
        toIdGetContent(id, data),
      );
      handleTransitionItemChange(key, newTransitionItem);
      setChoseList(updatedCheckTransition);
    } else {
      const updatedCheckTransition = choseList.filter((item) => item.id !== id);
      setChoseList(updatedCheckTransition);
      const dataIndex = data.findIndex((item) => item.id === id);
      const effectIndex = transition[key].effects.findIndex(
        (item) => (item as any).sub_type === data[dataIndex].content.sub_type,
      );
      handleTransitionItemEffectsRemove(key, effectIndex);
    }
  };

  const handleTransitionDurationChange = (e: number) => {
    const key = (videoGroupTransitionId - 1).toString();
    const transitionDuration = e * 1000;
    const transitionEntiry = {
      ...transition[key],
    };
    handleTransitionItemChange(key, {
      ...transitionEntiry,
      duration: transitionDuration,
    });
  };

  const onTransitionItemIsGlobalChange = (e: boolean) => {
    const key = videoGroupTransitionId - 1;
    const newTransitionItem: ITransitionItem = {
      is_global: e,
      duration: transition[key].duration,
      effects: transition[key].effects,
      start_sence: videoGroupTransitionId - 1,
    };
    handleTransitionItemChange(key.toString(), newTransitionItem);
    if (e) {
      handleTransitionItemIsGlobaltoTrue(key.toString());
    }
  };

  return (
    <>
      <VideoConfigCard>
        <VideoConfigCardHeader
          headerLeft={"转场设置"}
          headerRight={
            <>
              <Checkbox
                checked={transition[videoGroupTransitionId - 1]?.is_global}
                style={{ marginRight: 6 }}
                onChange={onTransitionItemIsGlobalChange}
              />
              应用到全部镜头
            </>
          }></VideoConfigCardHeader>
        <VideoConfigCardContainer>
          <div className="transition-header">
            <div className="transition-timer">
              时长
              <InputNumber
                size="mini"
                mode="button"
                max={1}
                min={0.1}
                step={0.1}
                value={
                  (transition[(videoGroupTransitionId - 1).toString()]
                    ?.duration || 0) / 1000
                }
                onChange={handleTransitionDurationChange}
                defaultValue={0.5}
                style={{ width: 100, margin: "0 6px" }}
              />
              秒
            </div>
            <ChoseDetailPopover
              showField={"name"}
              handleTagCloseBtnClick={(e) => {
                handleTransitionCardCheckboxChange(false, e as number);
              }}
              handleClearBtnClick={() => {
                handleTransitionItemClear(
                  (videoGroupTransitionId - 1).toString(),
                );
              }}
              opeatorFiled={"id"}
              data={choseList}
              title={"自动转场"}
            />
          </div>
          <TransitionGroup
            checkList={choseList}
            data={data}
            handleCheckboxChange={handleTransitionCardCheckboxChange}
          />
        </VideoConfigCardContainer>
      </VideoConfigCard>
    </>
  );
};

export default Trasnsition;
