import "./CoverCard.less";

export interface ICoverCardProps {
  url: string;
  style?: React.CSSProperties;
  active?: boolean;
  onClick: () => void;
}

const CoverCard: React.FC<ICoverCardProps> = (props) => {
  const { style, url, active, onClick } = props;
  return (
    <>
      <div
        style={style}
        className={`cover-card ${active ? "active" : ""}`}
        onClick={() => {
          onClick();
        }}>
        <div className="img-wrapper">
          <img src={url} alt="" />
        </div>
      </div>
    </>
  );
};

export default CoverCard;
