import { getAllPortfolioFolder } from "@/services/createSpace/portfolio";
import { getProjectList } from "@/services/createSpace/project";
import { useEffect, useState } from "react";

export interface IUseAllProjectAndPorflioFolderReturn {
  treeData: any;
  toFolderIdGetProjectId: (id: number) => void;
}

const useAllProjectAndPorfolioFolder = () => {
  const [treeData, setTreeData] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [folderList, setFolderList] = useState([]);

  const toFolderIdGetProjectId = (id: number) => {
    return folderList.find((item) => item.id === id).project_id;
  };

  const init = async () => {
    const result = await getProjectList("");
    const folders = await getAllPortfolioFolder();
    setProjectList(result);
    setFolderList(folders);
    const treeData = result.map((project) => {
      const secondLevelFolders = (folders || []).filter(
        (folder) => !folder.level && folder.project_id === project.id,
      );
      const secondLevelWithChildren = secondLevelFolders.map((folder) => {
        const children = (folders || []).filter(
          (child) => child.parent_id === folder.id,
        );
        return {
          ...folder,
          children: children.map((child) => ({
            type: "folder",
            key: project.id + ":" + child.id,
            title: child.name,
          })),
        };
      });
      return {
        key: project.id + ":" + project.name,
        title: project.name,
        type: "project",
        children: secondLevelWithChildren.map((folder) => ({
          type: "folder",
          key: project.id + ":" + folder.id,
          title: folder.name,
          children: folder.children,
        })),
      };
    });

    setTreeData(treeData);
  };

  useEffect(() => {
    init();
  }, []);

  return {
    treeData,
    toFolderIdGetProjectId,
  };
};

export default useAllProjectAndPorfolioFolder;
