import { memo } from "react";
import "./MixCutVideoMessageBar.less";

export interface IMixCutVideoMessageBar {
  videoNum: number;
  minDuration: number;
  maxDuration: number;
}

const MixCutVideoMessageBar: React.FC<IMixCutVideoMessageBar> = memo(
  (props) => {
    const { videoNum, minDuration, maxDuration } = props;
    return (
      <div className="mix-cut-video-wrapper">
        <ul>
          <li>
            <div className="title">预估视频最大数量</div>
            <div className="title">
              {(videoNum && videoNum) > 1000 ? "1000+" : videoNum}
              <span>个</span>
            </div>
          </li>
          <li style={{ marginLeft: 20 }}>
            <div className="title">预估单视频时长</div>
            <div className="title">
              {Number(minDuration).toFixed(0)} ~{" "}
              {Number(maxDuration).toFixed(0)}
              <span>秒</span>
            </div>
          </li>
        </ul>
      </div>
    );
  },
);

export default MixCutVideoMessageBar;
