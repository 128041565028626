import React from "react";
import "./ChoseDetailPopover.less";
import { Popover, Tag, TooltipProps } from "@arco-design/web-react";
import { IconClose } from "@arco-design/web-react/icon";

interface IChoseDetailPopover<T> {
  showField: keyof T;
  handleTagCloseBtnClick: (field: string | number) => void;
  handleClearBtnClick: () => void;
  opeatorFiled: keyof T;
  position?: TooltipProps["position"];
  data: T[];
  title: string;
}

const ChoseDetailPopover = <T,>(
  props: IChoseDetailPopover<T>,
): React.ReactElement => {
  const {
    showField,
    position,
    handleTagCloseBtnClick,
    data,
    opeatorFiled,
    title,
    handleClearBtnClick,
  } = props;
  return (
    <div className="chose-detail-bar-wrapper">
      {data.length > 0 ? (
        <span style={{ cursor: "pointer" }}>
          <span>已选择</span>： <span style={{ color: 'rgb(22, 93, 255)' }}>{data.length}</span>
          <Popover
            position={`${position || "br"}`}
            className="chose-detail-popover-wrapper"
            content={
              <div className="chose-detail-content-wrapper">
                {/*<div className="title-wrapper">*/}
                {/*  <span>*/}
                {/*    {title}(已选择{data.length})*/}
                {/*  </span>*/}
                {/*  <span*/}
                {/*    style={{ cursor: "pointer" }}*/}
                {/*    onClick={() => {*/}
                {/*      handleClearBtnClick();*/}
                {/*    }}>*/}
                {/*    清空*/}
                {/*  </span>*/}
                {/*</div>*/}
                <div className="chose-detail-content">
                  {(data || []).map((item) => (
                    <Tag
                      key={item?.toString()}
                      color={"blue"}
                      style={{ margin: "2px 2px" }}
                      className="chose-detail-popover-item">
                      {item[showField] as string}
                      <span>
                        <IconClose
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const value = item[opeatorFiled];
                            if (
                              typeof value === "string" ||
                              typeof value === "number"
                            ) {
                              handleTagCloseBtnClick(value);
                            }
                          }}
                        />
                      </span>
                    </Tag>
                  ))}
                </div>
              </div>
            }>
            <span
              style={{
                cursor: "pointer",
                color: "#165dff",
                marginLeft: "10px",
              }}>
              查看详情
            </span>
          </Popover>
          <span
            style={{ marginLeft: "10px" }}
            onClick={() => {
              handleClearBtnClick();
            }}>
            清空
          </span>
        </span>
      ) : (
        <span>已选择：{ data.length }</span>
      )}
    </div>
  );
};

export default ChoseDetailPopover;
