import { Checkbox } from "@arco-design/web-react";
import { useEffect, useState } from "react";
import VoiceSlider from "./VoiceSlider/VoiceSlider";
import TimberSelector from "./TimbreConfig/TimbreSelector/TimbreSelector";
import CustomTimbreSelector from "./TimbreConfig/CustomTimbreSelector/CustomTimbreSelector";
import OpenDrawer from "../OpenDrawer/OpenDrawer";
import "./VoiceConfig.less";
import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../../UIComponents/VideoConfigCard/VideoConfigCard";
import { getTimbre } from "@/services/mixCutDetail/mixCutDetail";
import useRequest from "@/requestHooks/mixCutDetail/useRequest";
import CustomMusicGroup from "../../BgConfig/BgMusicConfig/BgMusicTabs/CustomMusicGroup/CustomMusicGroup";
import { observer } from "mobx-react";
import useCaption from "@/useStore/mixCutDetail/useCaption";
import useGlobalCaption from "@/useStore/mixCutDetail/useGlobalCaption";
import { stat } from "fs";

export interface IVoiceConfig {
  style: React.CSSProperties;
  speedValue: number;
  pitchValue: number;
  speekerId: string;
  volume: number;
  global: boolean;
  is_global: boolean;
  handleSpeedValueChange: (e: number) => void;
  handlePitchValueChange: (e: number) => void;
  handleSpeekerIdChange: (e: string, name: string, rate: number) => void;
  handleVolumeChange: (e: number) => void;
  handleGlobalCaptionChange?: (e: boolean) => void;
}

const VoiceConfig: React.FC<IVoiceConfig> = observer((props) => {
  const {
    style,
    speedValue,
    pitchValue,
    speekerId,
    volume,
    handlePitchValueChange,
    handleSpeedValueChange,
    handleSpeekerIdChange,
    handleVolumeChange,
    is_global,
    global,
    handleGlobalCaptionChange,
  } = props;
  const [state, setState] = useState(true);
  const { data, handleRequest } = useRequest({
    request: getTimbre,
    initSearchData: { type: 1 },
  });
  const { projectCaption, handleProjectCaptionToIndexChange } = useCaption();
  const { globalCaptions, handleGlobalCationsChange } = useGlobalCaption();
  const [customVoice, setCustomVoice] = useState([]);
  const [collectionCustomVoice, setCollectionCustomVoice] = useState([]);
  const [timbreSelectorId, setTimbreSelector] = useState(1);
  const handleTimbreSelectorChange = (e) => {
    setTimbreSelector(e);
    handleRequest({ type: e });
  };

  const isCaptionSpeakerInitState = () => {
    projectCaption.forEach((item, groupIndex) => {
      item.forEach((step, index) => {
        if (
          !step.voice_setting.speaker_id &&
          !step.voice_setting.speed_ratio &&
          !step.voice_setting.speaker_name
        ) {
          const simple = { ...step };
          simple.voice_setting.default_speed_rate = data[0].speech_rate;
          simple.voice_setting.speaker_id = data[0].speaker_id;
          simple.voice_setting.speaker_name = data[0].speaker_style;
          handleProjectCaptionToIndexChange(groupIndex, index, simple, true);
        }
      });
    });
  };

  useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      projectCaption &&
      projectCaption.length > 0
    ) {
      isCaptionSpeakerInitState();
    }
  }, [data, projectCaption]);

  useEffect(() => {
    if (global) {
      if (isGlobalAddRate()) {
        processGlobalData();
      }
    } else {
      if (isAddRate()) {
        processData();
      }
    }
    if (data && data.length > 0 && !speekerId) {
      const name = `${data[0].name}_${data[0].speaker_style}`;
      const rate = data[0].speech_rate;
      handleSpeekerIdChange(data[0].speaker_id, name, rate);
    }
  }, [data, props.speekerId]);

  const isAddRate = () => {
    let state = false;
    if (!projectCaption || projectCaption.length === 0) {
      return false;
    }
    projectCaption.forEach((item, index) => {
      item.forEach((step, i) => {
        if (
          !step.voice_setting.default_speed_rate ||
          step.voice_setting.speaker_id.length > 0
        ) {
          state = true;
        }
      });
    });
    return state;
  };

  const isGlobalAddRate = () => {
    let state = false;
    if (!globalCaptions || globalCaptions.length === 0) {
      return false;
    }
    globalCaptions.forEach((item) => {
      if (
        !item.voice_setting.default_speed_rate ||
        item.voice_setting.speaker_id
      ) {
        state = true;
      }
    });
    return state;
  };

  const processGlobalData = () => {
    if (
      !globalCaptions ||
      globalCaptions.length === 0 ||
      !data ||
      data.length === 0
    ) {
      return;
    }
    globalCaptions.forEach((item, index) => {
      if (
        item.voice_setting.speaker_id &&
        !item.voice_setting.default_speed_rate
      ) {
        const timbreIndex = data.findIndex(
          (timbre) => timbre.speaker_id === item.voice_setting.speaker_id,
        );
        if (timbreIndex > -1) {
          const rate = data[timbreIndex].speech_rate;
          item.voice_setting.default_speed_rate = rate;
          handleGlobalCationsChange(index, item);
        }
      }
    });
  };

  const processData = () => {
    if (
      !projectCaption ||
      projectCaption.length === 0 ||
      !data ||
      data.length === 0
    ) {
      return;
    }
    projectCaption.forEach((item, index) => {
      item.forEach((step, i) => {
        if (
          step.voice_setting.speaker_id &&
          !step.voice_setting.default_speed_rate
        ) {
          const timbreIndex = data.findIndex(
            (timbre) => timbre.speaker_id === step.voice_setting.speaker_id,
          );
          if (timbreIndex > -1) {
            console.log(data[timbreIndex]);
            const rate = data[timbreIndex].speech_rate;
            step.voice_setting.default_speed_rate = rate;
            console.log(step);
            handleProjectCaptionToIndexChange(index, i, { ...step }, true);
          }
        }
      });
    });
  };

  return (
    <div style={style}>
      <VideoConfigCard>
        <VideoConfigCardHeader
          headerLeft={
            <>
              <OpenDrawer state={state} handleStateChange={setState} />
              <span
                onClick={() => {
                  setState(!state);
                }}>
                设置配音
              </span>
            </>
          }
          headerRight={
            <>
              {global ? (
                <></>
              ) : (
                <>
                  <Checkbox
                    checked={is_global}
                    onChange={(e) => {
                      if (handleGlobalCaptionChange) {
                        handleGlobalCaptionChange(e);
                      }
                    }}
                  >
                    <span style={{color: '#333333', fontSize: '12px' }}>应用到全部镜头配音</span>
                  </Checkbox>
                </>
              )}
            </>
          }></VideoConfigCardHeader>
        <VideoConfigCardContainer style={{ height: state ? "auto" : 0 }}>
          {state ? (
            <>
              <div className="timbre-selector">
                <TimberSelector
                  handleActiveIndex={handleTimbreSelectorChange}
                  activeId={timbreSelectorId}
                />
                <VoiceSlider
                  style={{ marginTop: 14 }}
                  title={"语速"}
                  isZero={true}
                  step={0.1}
                  showTicks={true}
                  max={2.5}
                  value={speedValue}
                  handleSliderChange={handleSpeedValueChange}
                />
                <VoiceSlider
                  style={{ marginTop: 14 }}
                  title={"音调"}
                  isZero={true}
                  step={0.1}
                  showTicks={true}
                  max={2}
                  value={pitchValue}
                  handleSliderChange={handlePitchValueChange}
                />
                <VoiceSlider
                  style={{ marginTop: 14 }}
                  title={"音量"}
                  step={1}
                  showTicks={false}
                  max={100}
                  value={Math.trunc(volume * 100)}
                  handleSliderChange={(e) => {
                    handleVolumeChange(e / 100);
                  }}
                />
              </div>
              <div className="custom-timbre-select-card-group">
                {timbreSelectorId === 4 ? (
                  <>
                    <CustomMusicGroup
                      handleSelfUploadBlockBtnClick={() => {
                        console.log("上传组件");
                      }}
                      handleDeleteCustomMusicItem={() => {
                        console.log("删除组件");
                      }}
                      collectionAudio={collectionCustomVoice}
                      data={customVoice}
                      handleCheckStateChange={() => {
                        console.log("跟新状态");
                      }}
                      multiple={false}
                    />
                  </>
                ) : (
                  <CustomTimbreSelector
                    handleTimbreItemClick={(e) => {
                      const item = data.find((item) => item.speaker_id === e);
                      const name = `${item.name}_${item.speaker_style}`;
                      const rate = item.speech_rate;
                      handleSpeekerIdChange(e, name, rate);
                    }}
                    activeId={speekerId}
                    data={data}
                    min={6}
                  />
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </VideoConfigCardContainer>
      </VideoConfigCard>
    </div>
  );
});

export default VoiceConfig;
