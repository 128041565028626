import { useEffect, useRef, useState } from "react";
import { Popover, Slider } from "@arco-design/web-react";
import {
  IconCaretRight,
  IconClose,
  IconMute,
  IconPause,
  IconSound,
} from "@arco-design/web-react/icon";
import "./MusicePlay.less";
import IconCloseBtn from "@/components/UI/IconCloseBtn/IconCloseBtn";
import useCoverAndBg from "@/useStore/mixCutDetail/useCoverAndBg";
export interface IMusicPlayProps {
  style?: React.CSSProperties;
  src: string;
  title: string;
  duration: number;
  handleCloseBtnClick: () => void;
}

export const formatPlayerTime = (seconds: number) => {
  const totalSeconds = Math.floor(seconds);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const secs = totalSeconds % 60;
  const formattedHours = hours > 0 ? String(hours).padStart(2, "0") + ":" : "";
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(secs).padStart(2, "0");
  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
};

const MusicPlayer: React.FC<IMusicPlayProps> = (props) => {
  const { handleBgAudioVolumn, bgAudioVolumn } = useCoverAndBg();
  const { src, title, duration, style, handleCloseBtnClick } = props;
  const [play, setPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState<string>("00:00");
  const [slideProcess, setSlideProcess] = useState(0);
  const [mute, setMute] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const handlePlayerChange = (e: boolean) => {
    if (audioRef.current) {
      if (e) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
      setPlay(e);
    }
  };

  const handleSoundChange = (e: boolean) => {
    if (audioRef.current) {
      if (e) {
        handleBgAudioVolumn(0);
        audioRef.current.muted = true;
      } else {
        audioRef.current.muted = false;
        handleBgAudioVolumn(50);
      }
    }
    setMute(e);
  };

  const hanldeProcessChange = (e: number) => {
    if (audioRef.current) {
      const current = Number((audioRef.current.duration / 100) * e);
      audioRef.current.currentTime = current;
      setCurrentTime(formatPlayerTime(current));
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const ref = audioRef.current;
      setCurrentTime(formatPlayerTime(ref.currentTime));
      setSlideProcess(Number((ref.currentTime / ref.duration) * 100));
    }
  };

  const handlePlayVolumeChange = (e: number) => {
    if (audioRef.current) {
      audioRef.current.volume = e / 100;
      handleBgAudioVolumn(e);
    }
    if (e > 0) {
      setMute(false); // 如果音量大于0，则取消静音
    }
  };

  useEffect(() => {
    if (src) {
      if (audioRef.current) {
        audioRef.current.volume = (bgAudioVolumn || 50) / 100;
        audioRef.current.play();
        setPlay(true);
      }
    }
  }, [src]);

  return (
    <div className="vl-audio-player" style={style}>
      <audio
        onTimeUpdate={handleTimeUpdate}
        src={src}
        ref={audioRef}
        style={{ display: "none" }}></audio>
      <div className="title">
        <span>{title}</span>
        {/* <div
          className="music-close-wrapper"
          onClick={() => {
            handleCloseBtnClick();
          }}>
          <IconClose style={{ fontSize: 7 }} />
        </div> */}
        <IconCloseBtn handleCloseBtnClick={handleCloseBtnClick} />
      </div>
      <div className="audio-cotainer">
        <div className="control-block">
          {!play ? (
            <div className="play">
              <IconCaretRight
                onClick={() => {
                  handlePlayerChange(true);
                }}
              />
            </div>
          ) : (
            <div className="pause">
              <IconPause
                onClick={() => {
                  handlePlayerChange(false);
                }}
              />
            </div>
          )}
        </div>
        <div className="time-count">
          <div className="current-time">{currentTime}</div>
          <div className="duration-time">/ {formatPlayerTime(duration)}</div>
        </div>
        <div className="play-process">
          <Slider
            tooltipVisible={false}
            defaultValue={0}
            value={slideProcess}
            onChange={(e) => {
              hanldeProcessChange(e as number);
            }}
          />
        </div>
        <div className="volumn" id="volumn">
          <Popover
            getPopupContainer={() => {
              return document.querySelector("#volumn");
            }}
            style={{ padding: 0 }}
            content={
              <Slider
                onChange={(e) => {
                  handlePlayVolumeChange(e as number);
                }}
                value={mute ? 0 : bgAudioVolumn || 50}
                style={{ width: 200 }}
                vertical={true}
              />
            }>
            {!mute ? (
              <IconSound
                onClick={() => {
                  handleSoundChange(true);
                }}
              />
            ) : (
              <IconMute
                onClick={() => {
                  handleSoundChange(false);
                }}
              />
            )}
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default MusicPlayer;
