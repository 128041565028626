import ChoiceItem from "./ChoiceItem";
import "./ChoiceGroup.less";
import { Fragment } from "react/jsx-runtime";
export interface ChoiceGroup {
  value?: string;
  list: { label: string; value: string | number; tip?: string }[];
  onChange?: (e) => void;
}

const ChoiceGroup: React.FC<ChoiceGroup> = (props) => {
  const { value, list, onChange } = props;
  return (
    <ul className="choice-group">
      {list.map((item, index) => {
        return (
          <Fragment key={item.label + item.value}>
            <ChoiceItem
              tip={item.tip}
              onClick={() => {
                onChange(item.value);
              }}
              style={{ marginRight: 6 }}
              value={item.label}
              active={list.findIndex((item) => item.value === value) === index}
            />
          </Fragment>
        );
      })}
    </ul>
  );
};

export default ChoiceGroup;
