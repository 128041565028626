import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import {
  Button,
  Form,
  Input,
  Popover,
  Radio,
  Upload,
  Image,
  Message,
  Tooltip,
} from "@arco-design/web-react";
import code from "@/assets/teamoverview/QRcode.png";
import entel from "@/assets/teamoverview/entel.png";
import mov_error from "@/assets/teamoverview/mov_error.png";
import mov_info from "@/assets/teamoverview/mov_info.png";
import merber from "@/assets/teamoverview/merber.png";
import siting from "@/assets/teamoverview/videocompositing.png";
import TeamRights from "@/assets/teamoverview/TeamRights.png";
import modaltitlicon from "@/assets/teamoverview/modaltitlicon.png";
import teambg from "@/assets/teamoverview/teambg.svg";
import companyImg from "@/assets/teamoverview/companylog.svg";
import titleImg from "@/assets/teamoverview/title.png";
import newtipImg from "@/assets/teamoverview/newtip.svg";
import cooperationImg from "@/assets/teamoverview/cooperation.svg";
import { camera_white_icon } from "@/assets/user-center";
import {
  IconClose,
  IconExclamationCircle,
  IconPlus,
  IconSearch,
} from "@arco-design/web-react/icon";
import { inject, observer } from "mobx-react";
import ContelSelect from "./ContelSelect";
import TableSelect from "./Table";
import Modals from "@/components/UI/Modals";
import Slesctinput from "./componts/slesctinput";
import { useNavigate } from "react-router-dom";
import { edit_icon } from "@/assets/headimage";
import _ from "lodash";
import User from "@/stores/userStore";
import { patchTeamLogApi, setTeamName } from "@/services/temeover";
import { getDocumentUploadToken } from "@/services/temeover";
import { getUserAvatarUrl } from "@/services/user/user";
import disableImg from "@/assets/member/disablemerber.png";
const FormItem = Form.Item;
const TeamOverview = (props: any) => {
  const { teamover, team } = props;
  const to = useNavigate();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [popstate, topopstate] = useState(false);
  const [rzsurrendata, setrzsurrendata] = useState(null);
  const fileRef = useRef(null);
  useEffect(() => {
    if (localStorage.getItem("UserStore")) {
      const arr = JSON.parse(localStorage.getItem("UserStore"));
      if (arr?.teamInfo?.team_user_role?.roles == null) {
        to("/");
      } else {
        if (
          arr?.teamInfo?.team_user_role?.roles.filter(
            (item) => item?.code == "superadmin",
          )
        ) {
          teamover.teme_package_to();
          team.team_membet_uuidget_to();
          teamover.team_corporate_get_to();
          teamover.user_wechat_qrcode_to();
          team.team_department_findid_to({
            page: null,
            page_size: null,
          });
          // teamover.equity_flow_to({team_id: 1, page: 1, page_size: 20})
        } else {
          to("/");
        }
      }
    }
  }, []);
  useEffect(() => {
    console.log(teamover.qaydata, "权益");
    setdata(teamover.qaydata);
  }, [teamover.qaydata]);
  useEffect(() => {
    setdata(teamover.qaydata);
  }, []);
  useEffect(() => {
    if (teamover.qyrzstatus) {
      setEnterprstate(teamover.qyrzstatus);
    }
    // setEnterprstate(3);
  }, [teamover.qyrzstatus]);
  useEffect(() => {
    if (teamover.qyrzinfodata) {
      setrzsurrendata(teamover.qyrzinfodata);
    }
  }, [teamover.qyrzinfodata]);
  const dataimg = {
    视频合成条数: siting,
    存储空间: entel,
    团队成员账号: merber,
    二维码: code,
  };
  const [data, setdata] = useState([]);
  const [Enterprstate, setEnterprstate] = useState(0);
  const Enterprisedata = ["企业认证", "审核中", "查看认证", "重新认证"];
  const [fileInputKey, setFileInputKey] = useState(0);
  const [prisestate, setprisestate] = useState(false);
  const [formRef] = Form.useForm();
  const [license, setlicense] = useState("");
  const dataref = useRef(null);
  const [resurrenstate, setresurrenstate] = useState(false);
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const inputRef = useRef(null);
  const [values, setValues] = useState<string>("");
  // 企业认证 弹框
  const HandeClickEnterprise = () => {
    if (Enterprstate != 1) {
      if (Enterprstate == 2) {
        setresurrenstate(true);
      } else {
        setprisestate(true);
      }
    }
  };
  const [editvisible, setEditVisible] = useState(false);

  const handestyle = () => {
    if (prisestate) {
      return "block";
    } else {
      return "none";
    }
  };
  const HandehoverPro = () => {
    if (!Enterprstate) return ``;
    if (Enterprstate == 1) {
      return (
        <p className="mov_pro_title_einfo">
          <img src={mov_info} alt="" />
          工作人员正在审核中请耐心等待
        </p>
      );
    } else if (Enterprstate == 3) {
      return (
        <p className="mov_pro_title_einfo">
          <img src={mov_error} alt="" />
          {teamover.qyrzReason || "认证失败"}
          {/* 审核已拒绝 */}
        </p>
      );
    } else if (Enterprstate == 2) {
      return (
        <div className="modal_qirzsurren_contel">
          <p className="modal_title_qyrzsurren">企业名称：</p>
          <p className="modal_title_qyrzsurren_bold">
            {rzsurrendata?.company_name}
          </p>
          <p className="modal_title_qyrzsurren">统一社会信用代码：</p>
          <p className="modal_title_qyrzsurren_bold">
            {rzsurrendata?.credit_code}
          </p>
          <p className="modal_title_qyrzsurren">法定代表人: </p>
          <p className="modal_title_qyrzsurren_bold">
            {rzsurrendata?.legal_rep}
          </p>
        </div>
      );
    }
  };
  const convertMbToGb = (mb, isNum = false): any => {
    if (typeof mb !== "number" || mb < 0) {
      throw new Error("输入必须是一个非负的数值。");
    }
    const GB = 1024;
    const TB = GB * 1024;

    if (mb >= TB) {
      const tb = mb / TB;
      // 保留两位小数，去除多余的零
      return isNum
        ? parseFloat(tb.toFixed(2))
        : `${parseFloat(tb.toFixed(2))} TB`;
    } else if (mb >= GB) {
      const gb = mb / GB;
      // 保留两位小数，去除多余的零
      return isNum
        ? parseFloat(gb.toFixed(2))
        : `${parseFloat(gb.toFixed(2))} GB`;
    } else {
      return isNum ? mb : `${mb} MB`;
    }
  };
  const HandeClickOpenVisble = () => {
    setprisestate(!prisestate);
  };
  const HandeoppenClick = () => {
    if (Enterprstate == 2) {
    } else {
      topopstate(!popstate);
    }
  };
  const gettopfangk = () => {
    if (Enterprstate != 2) {
      return "tr";
    } else {
      return "br";
    }
  };
  const handleFileChange = (e) => {
    const file = e;
    if (file) {
      // 立即预览图片
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handeUploadClick = async (fileList, file) => {
    if (file) {
      const { name } = file;
      // const { originFile } = file;
      // console.log(originFile);
      if (name) {
        const token = await teamover.getDocumentUploadToken_to(name, file.size);
        // const data = await teamover.setFile_teamOverview_to(token, originFile);
        const data = await teamover.setFile_teamOverview_to(token, file);
        const { object_key } = data;
        const slindata = await teamover.team_license_ocr_to(object_key);
        setlicense(object_key);
        setFileInputKey(fileInputKey + 1);
        if (slindata) {
          handleFileChange(file);
          formRef.setFieldsValue({
            input_Social: slindata?.name,
            input_Social_code: slindata?.register_number,
            input_Social_role: slindata?.legal_person,
          });
        }
      }
    }
  };
  const HandelClicksubmitstate = async () => {
    try {
      await formRef.validate();
      const input_Social = formRef.getFieldValue("input_Social");
      const input_Social_pag = formRef.getFieldValue("input_Social_pag");
      const input_Social_code = formRef.getFieldValue("input_Social_code");
      const input_Social_role = formRef.getFieldValue("input_Social_role");
      teamover.team_corporate_to({
        company_name: input_Social,
        credit_code: input_Social_code,
        legal_rep: input_Social_role,
        license: license,
      });
      setprisestate(!prisestate);
      // if (selectroledata?.length != null && seleData != null) {
      //   setvisbleadd(false);
      //   await team.team_invite_to({
      //     department_id: Number(seleData),
      //     role_id: selectroledata,
      //   });
      // }
    } catch (e) {
      console.error(e);
    }
  };
  const handleSetTeamName = async () => {
    if (!values || !values.trim()) {
      Message.warning("不能为空");
      return;
    }
    const res = await setTeamName({
      name: values,
    });
    if (res.code == 0) {
      User.getTeamInfo();
    } else {
      Message.error(res);
    }
  };
  const Unit = ({ value }) => {
    let count = value;
    let unit = "MB";
    if (value >= 1024 * 1024) {
      count = (value / (1024 * 1024)).toFixed(2);
      unit = "TB";
    } else if (value >= 1024) {
      count = (value / 1024).toFixed(2);
      unit = "GB";
    }
    return (
      <span>
        {count}
        <span style={{ fontSize: 14 }}>{unit}</span>
      </span>
    );
  };
  const handleCloseNotAuth = () => {
    teamover.setNotAuth(false);
  };
  useEffect(() => {
    if (inputFocus) {
      inputRef.current.focus();
    }
  }, [inputFocus]);
  return (
    <div className="team_overview">
      <div className="team_overview_header">
        <div className="header_contel">
          <div>
            <div className="basic-box">
              <span>基本信息</span>
              <Tooltip
                position="tl"
                trigger="hover"
                content="仅支持JPG、PNG、JPEG格式，文件小于1M（方形图）">
                <IconExclamationCircle className="basic-info" />
              </Tooltip>
            </div>
            <div className="contel_L">
              <div className="logo-img">
                <img
                  style={{ borderRadius: "50%" }}
                  width={78}
                  height={78}
                  src={team.team_logo || companyImg}
                  alt=""
                />
                <Upload
                  multiple
                  accept=".png, .jpg, .jpeg, .gif, .webp"
                  fileList={[]}
                  customRequest={async (file) => {
                    const { name, size } = file.file;

                    // 检查文件大小是否超过1MB
                    const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
                    if (size > maxSizeInBytes) {
                      Message.error({
                        content: "文件大小超过1MB，请选择较小的文件。",
                        duration: 2000,
                      });
                      return;
                    }
                    try {
                      const token = await getDocumentUploadToken(name, size);
                      const res: any = await teamover.setFile_teamOverview_to(
                        token,
                        file.file,
                      );
                      await patchTeamLogApi({
                        logo: res.object_key,
                      });

                      Message.success({
                        content: "上传成功",
                        duration: 1000,
                        onClose: () => {
                          // window.location.reload();
                          getUserAvatarUrl(res.object_key).then((res) => {
                            team.updateTeamLogo(res.data.url);
                          });
                        },
                      });
                    } catch (error) {
                      Message.error({
                        content: "上传失败，请重试。",
                        duration: 2000,
                      });
                    }
                  }}>
                  <div className="upload-mask">
                    <img src={camera_white_icon} alt="" width={28} />
                    <p>上传图片</p>
                  </div>
                </Upload>
              </div>
              <div className="contel_Lcomne">
                <p
                  className="team-name"
                  onMouseEnter={() => {
                    setEditVisible(true);
                  }}
                  onMouseLeave={() => {
                    setEditVisible(false);
                  }}>
                  {inputFocus ? (
                    <input
                      className="user-input"
                      ref={inputRef}
                      style={{
                        fontSize: 22,
                        height: 22,
                        paddingBottom: 10,
                      }}
                      value={values}
                      onChange={(e) => {
                        setValues(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleSetTeamName();
                          setInputFocus(false);
                        }
                      }}
                      onBlur={() => {
                        handleSetTeamName();
                        setInputFocus(false);
                      }}
                    />
                  ) : (
                    <div>
                      <span>
                        {values ||
                          _.get(
                            User.teamInfo,
                            "team_user_role.team.name",
                            "---",
                          )}
                      </span>
                      {editvisible && (
                        <img
                          style={{ marginLeft: 10 }}
                          src={edit_icon}
                          width={20}
                          alt=""
                          onClick={() => {
                            setInputFocus(true);
                            setValues(
                              values ||
                                _.get(
                                  User.teamInfo,
                                  "team_user_role.team.name",
                                  "---",
                                ),
                            );
                          }}
                        />
                      )}
                    </div>
                  )}
                </p>
                <p style={{ display: "flex", alignItems: "center" }}>
                  {/* 旗下
                {teamover?.supertoaldata?.total -
                  teamover?.supertoaldata?.supertotal || 0}
                名成员 */}
                  {Enterprstate == 2 && (
                    <span className="contel_Lcomne_renzhen">
                      <img
                        src={titleImg}
                        alt=""
                        style={{
                          width: "14px",
                          height: "14px",
                          marginRight: "3px",
                        }}
                      />
                      已认证
                    </span>
                  )}
                  <span>ID: {team?.team_id || "--"}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="contel_R">
            <Popover position={gettopfangk()} content={HandehoverPro()}>
              <Button
                className={`imgbutton    ${Enterprstate == 1 && `mov_btn_SH`}  ${Enterprstate == 2 && `mov_btn_Sueee`}  ${Enterprstate == 3 && `mov_btn_Error`}`}
                type="outline"
                onClick={HandeClickEnterprise}>
                {Enterprisedata[Enterprstate]}
              </Button>
            </Popover>
            <Popover
              // blurToHide
              trigger="click"
              position="br"
              onVisibleChange={() => {
                topopstate(!popstate);
              }}
              popupVisible={popstate} //
              // blurToHide={true}
              content={
                <Slesctinput HandeoppenClick={HandeoppenClick}></Slesctinput>
              }>
              <Button
                className="imgbutton"
                type="outline"
                onClick={() => {
                  HandeoppenClick();
                }}>
                移交团队
              </Button>
            </Popover>
          </div>
        </div>

        {data && (
          <div className="contel">
            <div className="contel-Item">
              <div className="top">
                <div className="top_left">
                  <h5>视频条数剩余</h5>
                  <p>
                    <span>{data[0]?.supertotal}</span>条
                  </p>
                </div>
                <img src={dataimg[data[0]?.name]} alt="" />
              </div>
              <div className="bottom">总视频条数: {data[0]?.total} 条</div>
            </div>
            <div className="contel-Item">
              <div className="top">
                <div className="top_left">
                  <h5>团队空间剩余</h5>
                  <p>
                    <Unit value={data[1]?.supertotal} />
                  </p>
                </div>
                <img src={dataimg[data[1]?.name]} alt="" />
              </div>
              <div className="bottom">
                {/* 权益套餐总空间：{} */}
                总存储空间: {convertMbToGb(data[1]?.total || 0)}
              </div>
            </div>
            <div className="contel-Item">
              <div className="top">
                <div className="top_left">
                  <h5>成员席位剩余</h5>
                  <p>
                    <span>
                      {data[2]?.supertotal}
                      <span style={{ fontSize: 14 }}>位</span>
                    </span>
                  </p>
                </div>
                <img src={dataimg[data[2]?.name]} alt="" />
              </div>
              <div className="bottom">总成员席位: {data[2]?.total} 位</div>
            </div>

            {/* {data &&
            data.map((item, index) => {
              return (
                <div key={index} className="contel-Item">
                  {item.name == "团队成员账号" ? (
                    <div className="top">
                      <div className="top_left">
                        <h5>{item.name}</h5>
                        <p>
                          <span>
                            {item.supertotal}
                            <span>位</span>
                          </span>
                          剩余
                        </p>
                      </div>
                      <img src={dataimg[item.name]} alt="" />
                    </div>
                  ) : (
                    <div className="top">
                      <div className="top_left">
                        <h5>{item.name}</h5>
                        <p>
                          <span>{item.supertotal}</span>
                          剩余
                        </p>
                      </div>
                      <img src={dataimg[item.name]} alt="" />
                    </div>
                  )}
                  <div className="bottom">权益套餐总条数：{item.total}</div>
                </div>
              );
            })} */}
            <div className="contel-Item" style={{ position: "relative" }}>
              {/* <div
                className="code_body"
                style={
                  teamover.ewcode
                    ? {}
                    : {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                }>
                {teamover.ewcode ? <img src={teamover.ewcode} alt="" /> : <></>}
                <div className="code_contel">
                  <h5>请扫描二维码添加进行咨询</h5>
                  <p>更多优惠与惊喜等你哟～</p>
                </div>
              </div> */}
              <img
                style={{ position: "absolute", left: "5px", top: "5px" }}
                src={newtipImg}
                alt=""
              />
              <img
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "62px",
                  width: "60%",
                }}
                src={cooperationImg}
                alt=""
              />
              {/* <span style={{ position: 'absolute', left: '5px', top: '92px', color: '#1f64ff'}}>高效协作剪辑工具</span> */}
              <img
                style={{ width: "100%", height: "100%" }}
                src={teambg}
                alt=""
              />
            </div>
            <div className="oppoation">
              <img src={TeamRights} alt="" />
            </div>
          </div>
        )}
      </div>
      <Modals
        width={579}
        title="企业认证"
        titleIcon={
          <img src={modaltitlicon} style={{ width: "26px", height: "26px" }} />
        }
        visible={prisestate}
        cancelBtn={<Button onClick={HandeClickOpenVisble}>取消</Button>}
        subMiteBtn={
          <Button
            type="primary"
            onClick={() => {
              HandelClicksubmitstate();
            }}>
            保存
          </Button>
        }
        closeIcon={<IconClose onClick={HandeClickOpenVisble} />}>
        <div className="modals_team_overview_qiy">
          <Form form={formRef} style={{ maxWidth: 500 }} autoComplete="off">
            <p className="topname"></p>
            <>
              <p className="input_Social ">营业执照</p>
              <FormItem
                field="input_Social_pag"
                // rules={[{ required: true, message: "请选择营业执照" }]}
              >
                {/* <div style={{ height: "30px" }}></div> */}
                <div className="contel_wrapp">
                  <div
                    className="upload_qyrz_conbtel"
                    style={{ position: "relative" }}>
                    <Upload
                      multiple
                      // disabled
                      // onChange={(fileList, file) => {
                      //   handeUploadClick(fileList, file);
                      // }}
                      customRequest={(file) => {
                        handeUploadClick("1", file.file);
                      }}>
                      <div className="trigger">
                        {/* <input
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          opacity: "0",
                          zIndex: "2",
                        }}
                        key={fileInputKey}
                        type="file"
                        name=""
                        onChange={(e) => {
                          handeUploadClick("1", e.target.files[0]);
                        }}></input> */}
                        <div className="contell_upload">
                          <IconPlus />
                          <p>点击上传</p>
                        </div>
                      </div>
                    </Upload>
                    {imagePreviewUrl && (
                      <Image
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          top: "0",
                          left: "0",
                          zIndex: "3",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        width={200}
                        height={132}
                        src={imagePreviewUrl}
                        alt="lamp"
                      />
                    )}
                  </div>
                  <div className="contell_upload_right">
                    <p>1、营业执照需要通过年检；</p>
                    <p>2、请使用原件/加盖公章的复印件，扫描/拍照后上传；</p>
                    <p>3、支持png、jpg格式，3mb以内</p>
                  </div>
                </div>
              </FormItem>
            </>

            <p className="input_Social">企业名称</p>
            <FormItem
              requiredSymbol={false}
              field="input_Social"
              rules={[{ required: true, message: "请输入企业名称" }]}>
              <Input
                style={{ width: 524, height: 36 }}
                allowClear
                placeholder="请输入"
              />
            </FormItem>
            <p className="input_Social">统一社会信用代码</p>
            <FormItem
              field="input_Social_code"
              rules={[{ required: true, message: "请输入统一社会信用代码" }]}>
              <Input
                style={{ width: 524, height: 36 }}
                allowClear
                placeholder="请输入"
              />
            </FormItem>
            <p className="input_Social">法定代表人</p>
            <FormItem
              field="input_Social_role"
              rules={[{ required: true, message: "请输入法定代表人" }]}>
              <Input
                style={{ width: 524, height: 36 }}
                allowClear
                placeholder="请输入"
              />
            </FormItem>
          </Form>
        </div>
      </Modals>
      <Modals
        width={464}
        height={230}
        title="提示"
        titleIcon={
          <img
            src={disableImg}
            alt=""
            style={{ width: "23px", height: "23px" }}
          />
        }
        visible={teamover.notAuthVisible}
        // cancelBtn={<Button>企业认证</Button>}
        subMiteBtn={
          <Button type="primary" onClick={handleCloseNotAuth}>
            确认
          </Button>
        }
        closeIcon={<IconClose onClick={handleCloseNotAuth} />}>
        <div className="disblae_modals">
          <div className="disblae_modals_body">
            <p className="disblae_modals_titeldis">暂时无法移交团队</p>
            <p className="disblae_modals_tit_wrapp">
              请先完成团队企业认证或是完成已选择成员的个人认证
            </p>
          </div>
        </div>
      </Modals>
      <div className="team_overview_content">
        <ContelSelect></ContelSelect>
        <TableSelect></TableSelect>
      </div>
    </div>
  );
};

export default inject("teamover", "team")(observer(TeamOverview));
