import noneImg from "@/assets/cut-mix-detail/bg-music-cover/none.png";
import { useState, useCallback, useEffect } from "react";
import { SketchPicker } from "react-color";
import { observer } from "mobx-react";
import useCoverAndBg from "@/useStore/mixCutDetail/useCoverAndBg";
import EmptyBgColor from "./ClearBgColor/ClearBgColor";
import "./BgColorChoseContainer.less";
import usePreviewConfig from "@/useStore/preview/usePreview";
const useList = ["#77C7EF", "#646FF4", "#E7E2FF", "#A7191B"];

export const recommend = [
  "#FFF9C4",
  "#FEFE8D",
  "#FBD96D",
  "#FFBB00",
  "#AB7433",
  "#B7DCF6",
  "#8EC2F9",
  "#459DF3",
  "#344D80",
  "#C5C4FF",
  "#E7E2FF",
  "#FFD9C6",
  "#FFB18C",
  "#FF8B40",
  // "#F56B00",
  // "#AB3837",
  // "#D5F1E9",
  // "#A1D5C5",
  // "#17B39E",
  // "#00917E",
  // "#03614B",
  // "#443A6C",
  "#000000",
];
export const recommend1 = [
  "#FFF9C4",
  "#FEFE8D",
  "#FBD96D",
  "#FFBB00",
  "#AB7433",
  "#B7DCF6",
  "#8EC2F9",
  "#459DF3",
  "#344D80",
  "#C5C4FF",
  "#E7E2FF",
  "#FFD9C6",
  "#FFB18C",
  "#FF8B40",
  "#F56B00",
  "#AB3837",
  "#D5F1E9",
  "#A1D5C5",
  "#17B39E",
  "#00917E",
  "#03614B",
  "#443A6C",
  "#664DCD",
  "#000000",
];

const BgColorChoseContainer = observer(() => {
  const { bgColor, handleBgChange } = useCoverAndBg();
  const { handlePreviewBackgroundChange, background } = usePreviewConfig();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleChangeComplete = (color) => {
    if (color?.hex === "transparent") {
      handleBgChange("");
    } else {
      handleBgChange(color?.hex);
    }
    handlePreviewBackgroundChange({ ...background, color });
    setDisplayColorPicker(false);
  };
  // 颜色选择器关闭逻辑
  const handleDocumentClick = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (
      !target.closest(".color-block-select") &&
      !target.closest(".color-picker-container")
    ) {
      setDisplayColorPicker(false);
    }
  }, []);
  useEffect(() => {
    // 绑定点击事件，点击任意地方关闭颜色选择器
    if (displayColorPicker) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }
    // 清理事件监听器
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [displayColorPicker, handleDocumentClick]);

  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  return (
    <div className="bg-color-chose-container">
      <div className="use">
        <div className="title">常用</div>
        <div className="color-block-wrapper">
          <div
            className="none"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleBgChange("");
            }}>
            <img src={noneImg} alt="" />
          </div>
          <div
            style={{
              background: `${bgColor ? bgColor : "white"}`,
              cursor: "pointer",
            }}
            className="color-block-select"
            onClick={() => {
              setDisplayColorPicker(true);
            }}>
            {bgColor ? <></> : <EmptyBgColor />}
            <div className="select">
              <div className="color-block"></div>
              {displayColorPicker ? (
                <div
                  className="color-picker-container"
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    top: "-111px",
                    left: "97px",
                  }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={handleClose}
                  />
                  <SketchPicker
                    color={bgColor}
                    onChangeComplete={handleChangeComplete}
                    presetColors={[
                      {
                        title: "白色",
                        color: "#ffffff",
                      },
                      ...recommend.map((item) => {
                        return { color: item };
                      }),
                    ]}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {useList.map((item, index) => {
            return (
              <div
                style={{
                  marginRight: 8,
                  background: item,
                  marginTop: 8,
                  cursor: "pointer",
                }}
                className={`color-block ${bgColor === item ? "active" : ""}`}
                onClick={() => {
                  handleBgChange(item);
                }}
                key={item + index}></div>
            );
          })}
        </div>
      </div>
      <div className="recommend">
        <div className="title">推荐</div>
        <div className="color-block-wrapper">
          {recommend1.map((item, index) => {
            return (
              <div
                onClick={() => {
                  handleBgChange(item);
                }}
                style={{
                  marginRight: 4,
                  background: item,
                  marginBottom: 8,
                  cursor: "pointer",
                }}
                className={`color-block ${bgColor === item ? "active" : ""}`}
                key={item + index}></div>
            );
          })}
          <div className="color-block"></div>
        </div>
      </div>
    </div>
  );
});

export default BgColorChoseContainer;
