import CheckBoxHoc from "@/components/business/MaterialFileModal/CheckBoxHoc/CheckBoxHoc";
import SelfUploadBlock from "../../../Components/SelfUploadBlock/SelfUploadBlock";
import CoverCard from "../CoverGroup/CoverCard/CoverCard";
import { IBgResponse } from "@/services/mixCutDetail/mixCutDetail";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import IconCloseBtn from "@/components/UI/IconCloseBtn/IconCloseBtn";
import "./CustomCover.less";
export interface ICustomCoverProps {
  handleCustomBtnClick: () => void;
  data: IMaterialDetail[];
  handleCoverChange: (e: boolean, id: number) => void;
  activeIds: IBgResponse[];
  handleCloseBtnClick: (id: number) => void;
}

const CustomCover: React.FC<ICustomCoverProps> = (props) => {
  const {
    handleCustomBtnClick,
    data,
    handleCoverChange,
    activeIds,
    handleCloseBtnClick,
  } = props;
  return (
    <div className="custom-cover-container" style={{ marginTop: '10px' }}>
      <SelfUploadBlock
        onClick={handleCustomBtnClick}
        style={{
          width: "84px",
          height: "84px",
          marginLeft: 4,
          marginBottom: 8,
        }}
      />
      {(data || []).map((item) => {
        return (
          <div className="customCover-wrapper" key={item.id}>
            <IconCloseBtn
              className="delete-icon"
              handleCloseBtnClick={() => {
                handleCloseBtnClick(Number(item.id));
              }}
            />
            <CheckBoxHoc
              style={{ borderRadius: 4 }}
              checked={
                (activeIds || []).findIndex(
                  (step) => Number(item.id) === Number(step.id),
                ) > -1
              }
              onCheckedChange={(e) => {
                handleCoverChange(e, Number(item.id));
              }}>
              <CoverCard
                onClick={() => {
                  handleCoverChange(
                    !(
                      (activeIds || []).findIndex(
                        (step) => Number(item.id) === Number(step.id),
                      ) > -1
                    ),
                    Number(item.id),
                  );
                }}
                active={
                  (activeIds || []).findIndex(
                    (step) => Number(item.id) === Number(step.id),
                  ) > -1
                }
                url={item?.play_info?.cover_url}></CoverCard>
            </CheckBoxHoc>
          </div>
        );
      })}
    </div>
  );
};

export default CustomCover;
