import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { Radio } from "@arco-design/web-react";
interface compontent {
  ID: number;
}
const RadioGroup = Radio.Group;
const ChildeMerbel = (props) => {
  const { ID, team, HandeonChangeradio, value } = props;
  const [data, setdata] = useState([]);

  useEffect(() => {
    team
      .team_merber_search_topo({
        department_id: ID,
        state: 3,
        page_size: 9999,
      })
      .then((res) => {
        console.log(res);
        let datalist = res.data.filter((item) => item.state != 1);
        // 未分组过滤掉有部门的人
        if (ID === 0) {
          datalist = datalist.filter((item) => (!item.department_name))
        }
        setdata(datalist || []);
      });
  }, []);

  return (
    <div>
      <RadioGroup direction="vertical" value={value}>
        {data &&
          data.map((item, index) => {
            return (
              <div key={item?.member_uuid}>
                <Radio
                  disabled={item.role_id.includes("1")}
                  value={item?.member_uuid}
                  onChange={(e) => {
                    HandeonChangeradio(item?.member_uuid);
                  }}>
                  {item?.member_name}
                </Radio>
              </div>
            );
          })}
          {
            !data?.length && <span>暂无数据</span>
          }
      </RadioGroup>
    </div>
  );
};
export default inject("team")(observer(ChildeMerbel));
