import { useEffect, useRef, useState } from "react";
import { createEditor } from "./editorUtil";
import "./index.less";
import {
  getEditingSticker,
  getVideoCompose,
} from "@/services/videoEditing/video";
import { useLocation } from "react-router-dom";
import VideoRes from "@/stores/VideoResult";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import ComponseModal from "../VideoClips/VideoClipResult/ComponseModal/ComponseModal";
import { toIdGetEngineer } from "@/services/createSpace/engineering";
import { compositionTaskInit } from "@/services/videoallyis";
import debounce from "lodash.debounce";
import { Message } from "@arco-design/web-react";
function VideoEditing() {
  const containerRef = useRef(null);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  // 函数用于加载外部脚本
  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      // 检查脚本是否已经存在
      const existingScript = document.querySelector(`script[src="${src}"]`);
      if (existingScript) {
        resolve(true);
        return;
      }

      const script = document.createElement("script");
      script.src = src;
      script.async = true;

      script.onload = () => {
        // console.log(`脚本 ${src} 加载完成`);
        resolve(true);
      };

      script.onerror = () => {
        // console.error(`脚本 ${src} 加载失败`);
        reject(new Error(`脚本 ${src} 加载失败`));
      };

      document.head.appendChild(script);
    });
  };

  // 函数用于加载外部样式表
  const loadCSS = (href) => {
    return new Promise((resolve, reject) => {
      // 检查样式表是否已经存在
      const existingLink = document.querySelector(`link[href="${href}"]`);
      if (existingLink) {
        resolve(true);
        return;
      }

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;

      link.onload = () => {
        // console.log(`样式表 ${href} 加载完成`);
        resolve(true);
      };

      link.onerror = () => {
        // console.error(`样式表 ${href} 加载失败`);
        reject(new Error(`样式表 ${href} 加载失败`));
      };

      document.head.appendChild(link);
    });
  };
  useEffect(() => {
    let isMounted = true; // 防止组件卸载后仍尝试更新
    const loadAssetsAndInit = async () => {
      try {
        // 并行加载脚本和样式表
        await Promise.all([
          loadScript(
            "https://g.alicdn.com/thor-server/video-editing-websdk/5.2.4/index.js",
          ),
          loadCSS(
            "https://g.alicdn.com/thor-server/video-editing-websdk/5.2.4/index.css",
          ),
        ]);

        if (!isMounted) return; // 组件已卸载，停止执行

        if (!containerRef.current) {
          console.log("containerRef.current 为 null");
          return;
        }

        const { init, destroy } = createEditor({
          container: containerRef.current,
        });

        try {
          init();
          // console.log("编辑器初始化成功");
        } catch (error) {
          console.error("编辑器初始化失败:", error);
        }

        // 清理函数，组件卸载时销毁编辑器
        return () => {
          try {
            destroy();
            console.log("编辑器销毁成功");
          } catch (error) {
            console.error("编辑器销毁失败:", error);
          }
        };
      } catch (error) {
        console.error("外部资源加载失败:", error);
      }
    };

    loadAssetsAndInit();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="Editor">
      <ComponseModal
        saveLoading={loading}
        mode="black"
        onCancel={() => {
          VideoRes.setVideoVisible();
        }}
        onOk={debounce(async (e) => {
          const sessionid = window.location.pathname.split("/").pop() || 0;
          setLoading(true);
          const { project_id } = await toIdGetEngineer(Number(sessionid));
          const res = await getVideoCompose({
            session_id: Number(sessionid),
            // 文件夹id
            folder_id: Number(e.parent_id),
            aspect_ratio: VideoRes.videoInfo?.aspectRatio,
            // 分辨率
            resolution: e.proportion,
            // 码率
            bitrate: Number(e.bitrate),
            fps: e.fps,
            timeline_id: VideoRes.videoTimeLine.id,
            timeline: JSON.stringify(VideoRes.videoInfo?.timeline),
            title: VideoRes.videoInfo?.title,
            project_id,
          });
          if (res && res.code == 0) {
            VideoRes.setTask_no(res.data.task_no);
            VideoRes.videoTimeLine = {
              id: 0,
              medias: [],
              rate: 0,
              time: "00:00",
              timeline: "",
              title: "",
            };
            VideoRes.setVideoVisible();
            Message.success({
              content: "合成任务已提交",
              duration: 2000,
              onClose: () => {
                setLoading(false);
              },
            });
          }
        }, 500)}
        visible={VideoRes.videoVisible}
        videoNumber={1}
      />
      <div ref={containerRef} style={{ height: "100vh" }} />
    </div>
  );
}

export default observer(VideoEditing);
