import { memo } from "react";

export interface ImgMemoProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  key: string;
}

const ImgMemo: React.FC<ImgMemoProps> = memo((props) => {
  return <img {...props} />;
});

export default ImgMemo;
