// src/components/ProtectedRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getTokenToLocalStorage } from "@/utils/token";

const ProtectedRoute = ({ children }) => {
  const token = getTokenToLocalStorage();
  const location = useLocation();

  if (!token) {
    // 如果没有token，重定向到登录页面，并传递当前路径作为next参数
    return (
      <Navigate
        to={`/login?next=${location.pathname + location.search}`}
        replace
      />
    );
  }

  // 如果有token，渲染子组件
  return children;
};

export default ProtectedRoute;
